import { Controller } from '@hotwired/stimulus'
import Formatter from '../../lib/formatter'

export default class extends Controller {
  static targets = ['headerExplanation', 'headerInfo', 'checkbox', 'activeItemsQuantity', 'activeItemsTotal']

  connect () {
    this.calculateActiveItems()

    if (!this.hasHeaderExplanationTarget) return

    this.observer = new IntersectionObserver(this.sticky.bind(this))
    this.observer.observe(this.headerExplanationTarget)
  }

  calculateActiveItems () {
    let activeItems = 0
    let price = 0

    this.checkboxTargets.forEach(element => {
      if (element.checked) {
        activeItems++
        price += Number(element.dataset.price)
      }
    })

    const formattedPrice = Formatter.currency(price)
    this.activeItemsTotalTarget.textContent = formattedPrice.substring(0, formattedPrice.length - 3)
    this.activeItemsQuantityTarget.textContent = activeItems
  }

  sticky (entries, observer) {
    entries.forEach((entry) => {
      this.headerInfoTarget.classList.toggle('sticky', !entry.isIntersecting)
    })
  }
}
