import { Controller } from '@hotwired/stimulus'
import Rails from '../../rails/rails-ujs'

export default class extends Controller {
  static targets = ['manual', 'places', 'address', 'lat', 'lng']

  connect () {
    if (this.latTarget.value) {
      this.showPlaces()
    } else {
      this.showManual()
    }
  }

  showPlaces () {
    this.manualTarget.classList.add('hidden')
    this.placesTarget.classList.remove('hidden')

    Rails.fire(this.placesTarget, 'show')
  }

  showManual () {
    this.manualTarget.classList.remove('hidden')
    this.placesTarget.classList.add('hidden')

    this.addressTarget.value = ''
    this.latTarget.value = ''
    this.lngTarget.value = ''
  }
}
