import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['image', 'button']

  connect () {
    if (this.hasImageTarget || this.hasButtonTarget) {
      this.checkImage = setInterval(this.loadImage.bind(this), 1000)
      if (this.hasButtonTarget) this.buttonTarget.disabled = true
    }
  }

  disconnect () {
    if (this.checkImage) {
      clearInterval(this.checkImage)
    }
  }

  /* ==== Support === */
  loadImage () {
    if (this.data.get('url')) {
      fetch(this.data.get('url')).then(response => response.json()).then(data => {
        if (data.url.startsWith('http')) {
          if (this.hasImageTarget) this.imageTarget.src = data.url
          if (this.hasButtonTarget) this.buttonTarget.disabled = false
          clearInterval(this.checkImage)
        }
      }).catch(() => {
        // Do nothing
      })
    }
  }
}
