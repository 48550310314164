import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect () {
    setTimeout(this.close.bind(this), 5000)
  }

  close () {
    if (document.body.contains(this.element)) this.element.remove()
  }
}
