import ParentController from './parent_controller'
import Rails from '../../../rails/rails-ujs'

/*
 * This controller is in charge of forms that contain multiple steps.
 *
 * Features:
 *   - Move forward, backward or to a specific point;
 *   - Cycle through the same steps multiple times;
 *   - Submit the form to different controllers based on the route the user took;
 *
 * Concepts:
 *   - Journey:
 *     - Contains all inputs that will be submitted;
 *     - If you need a single route, add all [tracks] to this element;
 *     - If you need multiple routes, add only the first [track] to this element;
 *   - Course:
 *     - Contains all tracks that the user can take before submiting the form;
 *     - If you need a single route, do no use this;
 *     - If you need multiple routes but a single form, create one course, and add all tracks to it;
 *     - If you need multiple forms, create one course for each one;
 *   - Track:
 *     - What the user actually sees. Each one is a single step in the form;
 *     - Create one track for each step you need. Add to this track the title, inputs and actions;
 *     - Tracks in the journey element must be ordered;
 *     - Tracks in the course element can be in any order;
 */
export default class extends ParentController {
  static targets = ['journey', 'course', 'track', 'abort']

  connect () {
    this.initJourney()
    this.initHistory()
    this.initEventHandlers()
  }

  disconnect () {
    window.removeEventListener('popstate', this.popHistoryCallback)
    document.removeEventListener('keypress', this.enterKeyCallback)
  }

  /* === initialization === */
  initJourney () {
    this.index = 0

    if (this.tracks.length === 0) {
      this.adjustCourse('main')

      const track = this.tracks[this.index]
      track.setAttribute('data-active', true)
      track.classList.remove(this.trackAheadClass)
      this.setTrackUniqueID(track)
    }
  }

  initHistory () {
    this.popHistoryCallback = this.popHistory.bind(this)
    window.addEventListener('popstate', this.popHistoryCallback)
    this.pushHistory()
  }

  initEventHandlers () {
    this.enterKeyCallback = this.enterKey.bind(this)
    document.addEventListener('keypress', this.enterKeyCallback)

    this.ajaxCompleteCallback = this.ajaxComplete.bind(this)
    this.journeyTarget.addEventListener('ajax:complete', this.ajaxCompleteCallback)
  }

  /* == actions == */
  next (e) {
    const { course, route, track, rewind } = e.currentTarget.dataset
    this.adjustCourse(course, track)
    this.adjustRoute(route)

    if (rewind) {
      this.rewind(e)
    } else {
      this.travel(this.index + 1)
      this.pushHistory()
    }
  }

  previous () {
    history.back()
  }

  goToAndRewind (e) {
    this.goTo(e)
    this.currentTrack.querySelectorAll('[data-action*="dynaform--navigation#next"]').forEach(element => {
      element.setAttribute('data-rewind', true)
    })
  }

  goTo (e) {
    const { track = 'unknown', trackId = -1, trackIndex = -1 } = e.currentTarget.dataset
    const index = this.tracks.findIndex((element, index) => {
      return element.dataset.track === track || element.dataset.trackId === trackId || index === parseInt(trackIndex, 10)
    })

    this.travel(index)
    this.pushHistory()
  }

  toggle () {
    const valid = this.currentTrack.dataset.valid !== 'false'
    this.currentTrack.querySelectorAll('.dynaform__button--next, .dynaform__button--skip, .dynaform__button--save').forEach((button) => {
      button.disabled = !valid
    })
  }

  rewind (e) {
    e.currentTarget.removeAttribute('data-rewind')
    history.back()
  }

  save (e) {
    this.ajaxTrigger = e.currentTarget
    setTimeout(() => { this.ajaxTrigger.disabled = true }, 0)
  }

  submit (e) {
    if (this.journeyTarget) Rails.fire(this.journeyTarget, 'submit')
    if (e) this.save(e)
  }

  /* == display == */
  travel (index) {
    try {
      const outgoing = this.tracks[this.index]
      const incoming = this.tracks[index]

      outgoing.removeAttribute('data-active')
      incoming.setAttribute('data-active', true)

      this.animateTrackChange(this.index, index, incoming, outgoing)
      this.setTrackUniqueID(incoming)
      this.index = index
    } catch (e) {
      // Do nothing. We have an architectural problem with dynaforms inside modals that were closed, because we can't go back to them, and we can't change the history.
    }
  }

  /* == logic == */
  adjustCourse (course, track) {
    if (!course) return
    this.course = course

    this.removeForwardTracks()
    this.loadTracksInCourse(course, track)
    this.updateJourney()
  }

  adjustRoute (route) {
    if (!route || (route === this.route && this.forwardTracks.length > 0)) return
    this.route = route

    this.removeForwardTracks()
    this.loadTracksInRoute()
  }

  removeForwardTracks () {
    this.forwardTracks.forEach(track => {
      track.remove()
    })
  }

  loadTracksInCourse (course, track) {
    const scope = this.findCourse(course)

    if (scope.dataset.atomic === 'true') {
      const allTracks = scope.querySelectorAll('[data-target][data-track]')
      allTracks.forEach(track => { this.loadTrack(this.course, track.dataset.track) })
    } else {
      const firstTrack = scope.querySelector('[data-target][data-track]')
      this.loadTrack(this.course, track || firstTrack.dataset.track)
    }
  }

  loadTracksInRoute () {
    const tracks = this.route.split(' ')
    tracks.forEach(track => this.loadTrack(this.course, track))
  }

  loadTrack (course, track) {
    const element = this.findTrack(course, track).cloneNode(true)
    element.classList.add(this.trackAheadClass)
    this.journeyTarget.appendChild(element)
  }

  animateTrackChange (currentIndex, newIndex, incoming, outgoing) {
    setTimeout(() => {
      if (newIndex > currentIndex) {
        outgoing.classList.add(this.trackBehindClass)
        incoming.classList.remove(this.trackAheadClass)
      } else {
        outgoing.classList.add(this.trackAheadClass)
        incoming.classList.remove(this.trackBehindClass)
      }
    }, 10)
  }

  setTrackUniqueID (track) {
    track.setAttribute('data-track-id', new Date().getTime())
  }

  updateJourney () {
    const course = this.element.querySelector(`[data-target][data-course="${this.course}"]`)
    this.journeyTarget.dataset.endTrack = course.dataset.endTrack

    const form = course.querySelector('form')
    this.journeyTarget.setAttribute('novalidate', form.noValidate)
    this.journeyTarget.setAttribute('action', form.action)
    this.journeyTarget.setAttribute('accept-charset', form.acceptCharset)
    this.journeyTarget.setAttribute('method', form.method)
    this.journeyTarget.setAttribute('data-remote', form.dataset.remote)

    // Copy the authenticity token, form method and any other rails special inputs
    course.querySelectorAll('form > input[type="hidden"]').forEach(element => {
      this.journeyTarget.insertAdjacentElement('afterBegin', element.cloneNode())
    })
  }

  findCourse (course) {
    return this.element.querySelector(`[data-target][data-course="${course}"]`)
  }

  findTrack (course, track) {
    const scope = this.findCourse(course)
    return scope.querySelector(`[data-target][data-track${track ? `='${track}'` : ''}]`)
  }

  /* == event handlers == */
  enterKey (e) {
    if (e.which === 13 && (document.activeElement.tagName !== 'TEXTAREA' || document.activeElement.className.indexOf('hero') !== -1)) {
      const submit = this.currentTrack.querySelector('.dynaform__track-actions [data-action~="click->dynaform--navigation#next"], .dynaform__track-actions [data-action~="click->dynaform--navigation#save"]')
      if (submit) {
        document.activeElement.blur()
        submit.click()
      }

      e.preventDefault()
      return false
    }
  }

  ajaxComplete (e) {
    const status = e.detail[0].status

    if (status >= 200 && status < 300 && this.ajaxTrigger) {
      Rails.fire(this.ajaxTrigger, 'saved')
    }

    if (this.ajaxTrigger) this.ajaxTrigger.disabled = false
  }

  /* == history == */
  pushHistory () {
    const data = { index: this.index }
    const title = `Passo ${this.index + 1}`
    this.index === 0 ? history.replaceState(data, title) : history.pushState(data, title)
  }

  popHistory (e) {
    /*
     * If you are already at the beginning of the form and the user hits back,
     * fire a click on the abort target so that componentes (such as modal) can react to it.
     * Otherwise, travel as usual.
     */
    if (e.state.index === 0 && this.index === 0) {
      this.hasAbortTarget && Rails.fire(this.abortTarget, 'click')
    } else if (e.state.index !== undefined) {
      this.travel(e.state.index)
    }
  }

  /* == getters and setters == */

  get trackAheadClass () {
    return this.data.get('trackAheadClass') || 'hidden'
  }

  get trackBehindClass () {
    return this.data.get('trackBehindClass') || 'hidden'
  }

  get course () {
    return this.data.get('course')
  }

  set course (value) {
    this.data.set('course', value)
  }

  get route () {
    return this.data.get('route')
  }

  set route (value) {
    this.data.set('route', value)
  }
}
