import { Controller } from '@hotwired/stimulus'
import Dom from '../../lib/dom'

export default class extends Controller {
  static targets = ['source', 'destination']

  /* ==== Life Cycle ==== */
  initialize () {
    this.observer = new MutationObserver((mutations) => {
      this.copy(mutations[0].target)
    })
  }

  connect () {
    this.sourceTargets.forEach(element => {
      this.observer.observe(element, { attributes: true })
    })
  }

  disconnect () {
    this.observer.disconnect()
  }

  /* ==== Action ==== */
  changed (event) {
    this.copy(event.currentTarget)
  }

  /* ==== Support ==== */
  copy (element) {
    const identifier = element.dataset.binderIdentifier

    if (identifier) {
      this.targetedCopy(element, identifier)
    } else {
      this.standardCopy(element)
    }
  }

  targetedCopy (source, identifier) {
    const value = Dom.get(source)
    this.element.querySelectorAll(`[data-binder-target="destination"][data-binder-identifier='${identifier}'], [id='${identifier}']`).forEach(element => {
      Dom.set(element, value, { event: 'copied', attribute: source.dataset.binderDestinationAttribute })
    })
  }

  standardCopy (source = this.sourceTarget) {
    const value = Dom.get(source)
    this.element.querySelectorAll('[data-binder-target="destination"]:not([data-binder-identifier])').forEach(element => {
      Dom.set(element, value, { event: 'copied', attribute: source.dataset.binderDestinationAttribute })
    })
  }
}
