import { Controller } from '@hotwired/stimulus'
import Dom from '../../lib/dom'

export default class extends Controller {
  static targets = ['multiplier', 'multiplicand', 'product']

  initialize () {
    this.observer = new MutationObserver(this.multiply.bind(this))
  }

  connect () {
    this.observer.observe(this.multiplierTarget, { attributes: true })
    this.observer.observe(this.multiplicandTarget, { attributes: true })
  }

  disconnect () {
    this.observer.disconnect()
  }

  multiply () {
    const multiplier = Number(Dom.get(this.multiplierTarget))
    const multiplicand = Number(Dom.get(this.multiplicandTarget))

    this.product = (multiplicand * multiplier).toFixed(2)
  }

  set product (value) {
    Dom.set(this.productTarget, value, { event: 'multiplied' })
  }
}
