import { Controller } from '@hotwired/stimulus'
import Formatter from '../../../lib/formatter'

export default class extends Controller {
  static targets = ['list', 'itemQuantity', 'itemTotal', 'quantity', 'subtotal', 'total']

  initialize () {
    this.observer = new MutationObserver(this.cartItemsChanged.bind(this))
  }

  connect () {
    this.observer.observe(this.listTarget, { childList: true, subtree: true })
  }

  disconnect () {
    this.observer.disconnect()
  }

  // ---- Business Logic ----//
  cartItemsChanged () {
    this.quantity = this.calculateItemsQuantity()
    this.subtotal = this.calculateItemsTotal()
    this.total = this.calculateItemsTotal()
  }

  calculateItemsTotal () {
    return this.itemTotalTargets.reduce((total, element) => {
      return total + Number(element.dataset.total)
    }, 0)
  }

  calculateItemsQuantity () {
    return this.itemQuantityTargets.reduce((total, element) => {
      return total + Number(element.value)
    }, 0)
  }

  // Getters & Setters
  set quantity (value) {
    if (value === 1) {
      this.quantityTarget.textContent = `${value} produto`
    } else {
      this.quantityTarget.textContent = `${value} produtos`
    }
  }

  set subtotal (value) {
    this.subtotalTarget.textContent = Formatter.currency(value)
  }

  set total (value) {
    if (this.hasTotalTarget) this.totalTarget.textContent = Formatter.currency(value)
  }
}
