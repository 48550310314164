import { Controller } from '@hotwired/stimulus'
import loadImage from 'blueimp-load-image/js/load-image'

export default class extends Controller {
  static targets = ['previewImage', 'file']

  change (e) {
    const file = e.target.files[0]
    if (!!file && (/image\/(jpg|jpeg|png|webp|gif)/.test(file.type))) {
      loadImage(file, (img) => {
        this.previewImageTarget.src = img.src
        this.data.set('loaded', true)
      }, { noRevoke: true })
    } else {
      e.target.value = ''
    }
  }
}
