import { Controller } from '@hotwired/stimulus'
import Dom from '../../lib/dom'

export default class extends Controller {
  static targets = ['summand', 'total']
  static values = { sumOnConnect: Boolean }

  /* ==== Life Cycle ==== */
  initialize () {
    this.observer = new MutationObserver(this.sum.bind(this))
  }

  connect () {
    this.summandTargets.forEach(element => {
      this.observer.observe(element, { attributes: true })
    })

    if (this.sumOnConnectValue) this.sum()
  }

  disconnect () {
    this.observer.disconnect()
  }

  /* ==== Support ==== */
  sum () {
    const sums = {}

    // Find every summand and totalize it, grouping by the identifier
    this.summandTargets.forEach(element => {
      const identifiers = (element.dataset.additionIdentifier || 'default').split(' ')

      identifiers.forEach(identifier => {
        sums[identifier] = sums[identifier] || 0
        sums[identifier] += Number(element.dataset.value)
      })
    })

    // For every summand identifier previously found, locate the target and set it
    Object.keys(sums).forEach(identifier => {
      if (identifier === 'default') {
        const total = this.element.querySelector('[data-addition-target="total"]:not([data-addition-identifier])')
        Dom.set(total, sums[identifier])
      } else {
        const total = this.element.querySelector(`[data-addition-target="total"][data-addition-identifier='${identifier}']`)
        Dom.set(total, sums[identifier])
      }
    })
  }
}
