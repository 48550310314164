import { Controller } from '@hotwired/stimulus'
import Rails from '../../rails/rails-ujs'

export default class extends Controller {
  static targets = ['content']
  static classes = ['loader']
  static values = { url: String, cache: Boolean }

  fetch (e) {
    if (this.cached) return

    const trigger = e.currentTarget
    this.start(trigger)

    Rails.ajax({
      url: this.urlValue,
      type: 'GET',
      success: (_response, _status, xhr) => {
        this.contentTarget.innerHTML = xhr.response
      },
      complete: () => {
        this.complete(trigger, false)
      }
    })
  }

  start (trigger) {
    trigger.disabled = true
    this.contentTarget.classList.add(this.loaderClass)
    this.contentTarget.innerHTML = ''
  }

  complete (trigger) {
    trigger.disabled = false
    this.contentTarget.classList.remove(this.loaderClass)
  }

  get cached () {
    return this.cacheValue && this.contentTarget.childElementCount > 0
  }

  get url () {
    return this.data.get('url')
  }
}
