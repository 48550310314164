import { Controller } from '@hotwired/stimulus'
import Url from '../../lib/url'
import Rails from '../../rails/rails-ujs'

export default class extends Controller {
  static values = { item: String, price: Number, checkout: String }

  connect () {
    this.initButton()
  }

  initButton () {
    const checkPresence = setInterval(() => {
      if (typeof paypal === 'object' && typeof paypal.Buttons === 'function') {
        clearInterval(checkPresence)
        paypal.Buttons({

          style: {
            color: 'white',
            size: 'responsive',
            shape: 'rect',
            label: 'paypal',
            layout: 'horizontal',
            fundingicons: 'false',
            tagline: 'false',
            height: 55
          },

          createOrder: this.createOrder.bind(this),
          onApprove: this.onApprove.bind(this)
        }).render('#paypal-button-container')
      }
    }, 100)
  }

  createOrder (data, actions) {
    return actions.order.create({
      purchase_units: [{
        description: this.itemValue,
        amount: {
          value: this.priceValue,
          currency_code: 'BRL'
        }
      }],
      application_context: {
        brand_name: 'FestaLab',
        locale: 'pt-BR',
        shipping_preference: 'NO_SHIPPING'
      }
    })
  }

  onApprove (data, actions) {
    document.getElementById('loader').classList.toggle('hidden')
    document.getElementById('paypal-button').classList.toggle('hidden')

    const checkoutId = this.checkoutValue
    return actions.order.capture().then(function (details) {
      const params = {
        paypal_order_id: data.orderID,
        paypal_payer_id: data.payerID,
        paypal_status: details.status,
        email: details.payer.email_address
      }

      Rails.ajax({
        url: `/checkout/${checkoutId}/convite/paypal`,
        type: 'POST',
        data: Url.unencodedQueryString(params)
      })
    })
  }
}
