import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect () {
    this[this.style]()
  }

  temporary () {
    setTimeout(() => {
      this.element.remove()
    }, 4000)
  }

  form () {
    // do nothing for now;
  }

  get style () {
    return this.data.get('style')
  }
}
