import { Controller } from '@hotwired/stimulus'
import Rails from '../../rails/rails-ujs'

export default class extends Controller {
  connect () {
    setTimeout(() => this.render(), this.delay)
  }

  render () {
    Rails.ajax({
      type: 'GET',
      url: `${this.url}${this.queryString()}`,
      success: (response) => {
        this.element.outerHTML = response
      }
    })
  }

  queryString () {
    const { width, height } = this.boundaries

    let params = ''
    if (!!width && width > 0 && this.forwardWidth) params += `&width=${width}`
    if (!!height && height > 0 && this.forwardHeight) params += `&height=${height}`

    return params
  }

  get boundaries () {
    return this.forwardWidth || this.forwardHeight ? this.element.getBoundingClientRect() : {}
  }

  get forwardBoundaries () {
    return this.data.get('forwardBoundaries') === 'true'
  }

  get forwardWidth () {
    return this.data.get('forwardWidth') === 'true' || this.forwardBoundaries
  }

  get forwardHeight () {
    return this.data.get('forwardHeight') === 'true' || this.forwardBoundaries
  }

  get url () {
    return this.data.get('url')
  }

  get delay () {
    return Number(this.data.get('delay') || 10)
  }
}
