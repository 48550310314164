import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = { redirectTo: String }
  static classes = { success: String }

  initialize () {
    this.successCallback = this.success.bind(this)
    this.errorCallback = this.error.bind(this)
  }

  connect () {
    this.element.addEventListener('ajax:success', this.successCallback)
    this.element.addEventListener('ajax:error', this.errorCallback)
  }

  disconnect () {
    this.element.removeEventListener('ajax:success', this.successCallback)
    this.element.removeEventListener('ajax:error', this.errorCallback)
  }

  /* ==== Support ==== */
  success () {
    this.element.setAttribute('data-ajax', 'success')

    if (this.hasRedirectToValue) {
      Turbo.cache.clear()
      Turbo.visit(this.redirectToValue, { action: 'replace' })
    }
  }

  error (data) {
    const id = data.detail[0].body.firstElementChild.id

    if (!id) {
      document.querySelector('main').outerHTML = data.detail[0].querySelector('main').outerHTML
    } else {
      document.querySelector(`#${id}`).outerHTML = data.detail[0].querySelector(`#${id}`).outerHTML
    }
  }
}
