import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['journey']

  get behindTracks () {
    const behindTracks = []

    this.tracks.forEach((track, index) => {
      if (index < this.index) behindTracks.push(track)
    })

    return behindTracks
  }

  get forwardTracks () {
    const forwardTracks = []

    this.tracks.forEach((track, index) => {
      if (index > this.index) forwardTracks.push(track)
    })

    return forwardTracks
  }

  get currentTrack () {
    return this.tracks[this.index]
  }

  get tracks () {
    return Array.from(this.journeyTarget.querySelectorAll('[data-target*="track"][data-track]'))
  }

  get index () {
    return Number(this.element.dataset.index)
  }

  set index (value) {
    this.element.setAttribute('data-index', value)
  }
}
