import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = []

  connect () {
    const controllers = this.element.getAttribute('data-controller')
    this.element.setAttribute('data-controller', `${controllers} dynaform--navigation dynaform--validation dynaform--progress dynaform--display`)
  }
}
