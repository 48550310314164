import Util from './util'

const Social = {
  whatsapp (text) {
    return 'https://api.whatsapp.com/send?text=' + window.encodeURIComponent(text)
  },

  facebook (url) {
    const appId = Util.facebookAppId()

    if (Util.isMobile()) {
      return `fb-messenger://share/?link=${url}&app_id=${appId}`
    } else {
      return `http://www.facebook.com/dialog/send?link=${url}&app_id=${appId}&redirect_uri=${url}`
    }
  }
}

export default Social
