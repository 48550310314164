import { Controller } from '@hotwired/stimulus'
import Rails from '../../rails/rails-ujs'
import Text from '../../lib/text'

export default class extends Controller {
  static targets = ['zipcode', 'street', 'neighborhood', 'city', 'state', 'address']

  connect () {
    this.searchCache = {}
  }

  search (e) {
    const value = e.target.value
    if (e.target.value.length >= 9) {
      this.get(value.replace(/\D/g, ''))
      Rails.fire(e.target, 'zipcode')
    }
  }

  forceSearch (e) {
    const key = e.which || e.keyCode

    if (key === 13) { // 13 is enter
      e.preventDefault()
      const val = e.target.value.replace(/\D/g, '')
      this.get(val)
      return false
    } else {
      return true
    }
  }

  programmaticSearch () {
    const val = this.zipcodeTarget.value.replace(/\D/g, '')
    const cache = this.searchCache[val]
    this.updateTargets(cache, false, false)
  }

  get (val) {
    const cache = this.searchCache[val]
    if (cache) {
      this.updateTargets(cache, false)
    } else {
      this.fetch(val)
    }
  }

  fetch (val) {
    this.updateTargets()

    Rails.ajax({
      type: 'GET',
      url: '/api/zipcodes/' + val + '.json',
      success: (response) => {
        if (response) {
          this.searchCache[val] = response
          this.updateTargets(response, false)
          this.hasNumberTarget && this.numberTarget.focus()
        } else {
          this.searchCache[val] = {}
          this.updateTargets({}, false)
          this.hasStreetTarget && this.streetTarget.focus()
        }
      },
      error: () => {
        this.updateTargets({}, false)
      }
    })
  }

  updateTargets (data = {}, disable = true, force = true) {
    this.zipcodeTarget.disabled = disable

    this.setStreet(data.street, disable, force)
    this.setNeighborhood(data.neighborhood, disable, force)
    this.setCity(data.city, disable, force)
    this.setState(data.state, disable, force)
  }

  setStreet (street, disable = true, force = false) {
    if (this.hasStreetTarget) {
      this.streetTarget.disabled = disable
      this.setField(this.streetTarget, street, force)
    }
  }

  setNeighborhood (neighborhood, disable = true, force = false) {
    if (this.hasNeighborhoodTarget) {
      this.neighborhoodTarget.disabled = disable
      this.setField(this.neighborhoodTarget, neighborhood, force)
    }
  }

  setCity (city, disable = true, force = false) {
    if (this.hasCityTarget) {
      this.cityTarget.disabled = disable
      this.setField(this.cityTarget, city, force)
    }
  }

  setState (state, disable = true, force = false) {
    if (this.hasStateTarget) {
      this.stateTarget.disabled = disable
      this.setField(this.stateTarget, state, force)
    }
  }

  setField (target, val, force) {
    if (Text.isPresent(target.value) && !force) return

    target.value = val || ''
    target.classList.toggle('filled')

    this.disableFormatter(target)
    const event = target.tagName === 'SELECT' ? 'change' : 'input'
    Rails.fire(target, event)
    this.enableFormatter(target)
  }

  disableFormatter (input) {
    if (!input.dataset.formatter) return

    input.dataset.disabledFormatter = input.dataset.formatter
    delete input.dataset.formatter
  }

  enableFormatter (input) {
    if (!input.dataset.disabledFormatter) return

    input.dataset.formatter = input.dataset.disabledFormatter
    delete input.dataset.disabledFormatter
  }
}
