import { Controller } from '@hotwired/stimulus'
import Text from '../../lib/text'
import Dom from '../../lib/dom'

export default class extends Controller {
  static targets = ['button', 'field', 'every']
  static values = { every: Boolean }

  /* ==== Life Cycle ==== */
  connect () {
    if (this.hasButtonTarget) this.enableButton()
  }

  /* ==== Actions ==== */
  enableButton () {
    const enable = this.everyValue ? this.fieldTargets.every(this.isFilled) : this.fieldTargets.some(this.isFilled)
    this.buttonTarget.disabled = !enable
  }

  /* ==== Support ==== */
  isFilled (element) {
    return Text.isPresent(Dom.get(element))
  }
}
