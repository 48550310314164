import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect () {
    this.initWidgets()
    this.initAjaxEventHandlers()
  }

  initWidgets () {
    this.element.dataset.controller = 'application ajax util'
  }

  initAjaxEventHandlers () {
    document.body.addEventListener('ajax:send', (e) => {
      if (e.target.dataset.loader === 'page') this.showLoader()
    })

    document.body.addEventListener('ajax:complete', () => {
      this.removeLoader()
    })
  }

  showLoader () {
    document.body.insertAdjacentHTML('beforeend', '<div id="page-loader" data-target="pageLoader"><div class="loader loader--dots"><div class="duo duo--left"><div class="dot dot--left"></div><div class="dot dot--right"></div></div><div class="duo duo--right"><div class="dot dot--left"></div><div class="dot dot--right"></div></div></div></div>')
  }

  removeLoader () {
    const loader = document.getElementById('page-loader')
    if (loader) loader.remove()
  }
}
