import { Controller } from '@hotwired/stimulus'
import Formatter from '../../lib/formatter'
import Validator from '../../lib/validator'
import Text from '../../lib/text'
import Rails from '../../rails/rails-ujs'

export default class extends Controller {
  static targets = ['flash', 'input', 'step', 'loadingStep', 'errorStep', 'title', 'counter', 'progress', 'previousButton', 'destination']

  // ---- Life Cycle ---- //
  connect () {
    this.initEventHandlers()
    this.initSteps()
    this.initHistory()
  }

  disconnect () {
    window.removeEventListener('popstate', this.popHistoryCallback)
    document.removeEventListener('keypress', this.keypressCallback)
  }

  // ---- Initialization ---- //
  initEventHandlers () {
    this.keypressCallback = this.keypress.bind(this)
    document.addEventListener('keypress', this.keypressCallback)
  }

  initSteps () {
    if (this.hasCounterTarget) this.counterMax = this.counterTarget.textContent.split('/')[1]
    this.index = 0
  }

  initHistory () {
    if (!this.isMultiStep) return

    this.popHistoryCallback = this.popHistory.bind(this)
    window.addEventListener('popstate', this.popHistoryCallback)

    this.pushHistory()
  }

  // ---- Actions (automatic) ---- //
  input (e) {
    const input = e.currentTarget

    this.removeFlash()
    this.removeFieldError(input)
    this.toggleFilled(input)
    this.applyFormat(input)
    this.enforceMaxLength(input)
    this.updateCounter(input)

    input.dataset.previousValue = input.value
  }

  change (e) {
    const input = e.currentTarget

    this.removeFlash()
    this.removeFieldError(input)
    this.toggleFilled(input)
    this.applyFormat(input)
    this.enforceMaxLength(input)
    this.display(input)

    input.dataset.previousValue = input.value
  }

  display (e) {
    this.destinationTargets.forEach((element) => {
      this.setValue(element, e.value)
    })
  }

  setValue (element, value) {
    if (element.tagName === 'IMG') {
      element.src = value
    } else {
      element.value = value
    }
  }

  blur (e) {
    const input = e.currentTarget

    this.applyBlurFormat(input)
    this.validateField(input)
  }

  keypress (e) {
    const key = e.which || e.keyCode
    if (key !== 13 || !this.isMultiStep) return true

    const current = e.target
    const fields = Array.from(this.currentStep.querySelectorAll('input:not([disabled]), select:not([disabled])'))
    const index = fields.indexOf(current)

    if (index < fields.length - 1) {
      fields[index + 1].focus()
    } else {
      Rails.fire(this.currentStep.querySelector('button'), 'click')
    }
  }

  // ---- Actions (navigation) ---- //
  previous () {
    history.back()
  }

  next () {
    if (this.validate()) {
      this.navigate(this.index + 1)
      this.pushHistory()
    }
  }

  goTo (e) {
    const index = Number(e.currentTarget.dataset.step) - 1
    this.navigate(index)
  }

  submit () {
    if (this.validate()) {
      this.loading()
      // Give a chance to whatever triggered the submit to finish running its event chain.
      setTimeout(() => Rails.fire(this.element, 'submit'), 10)
    }
  }

  done () {
    if (this.hasLoadingStepTarget) this.loadingStepTarget.classList.add('hidden')
  }

  loading () {
    if (this.hasLoadingStepTarget) this.loadingStepTarget.classList.remove('hidden')
  }

  // ---- Actions (browser buttons) ---- //
  pushHistory () {
    const data = { index: this.index }
    const title = this.currentStep.dataset.title
    this.index === 0 ? history.replaceState(data, title) : history.pushState(data, title)
  }

  popHistory (e) {
    const index = e.state.index
    if (index !== undefined) this.navigate(index)
  }

  // ---- Actions (other) ---- //
  addInput (e) {
    const div = document.createElement('div')
    div.className = 'form__input form__input--removable'

    const input = document.createElement('input')
    input.type = 'text'
    input.name = e.target.dataset.inputName
    input.className = e.target.dataset.inputClass
    input.maxLength = 255
    input.size = 255
    input.setAttribute('data-target', 'form.input')
    input.setAttribute('data-action', 'input->form#input')
    input.setAttribute('placeholder', e.target.dataset.inputPlaceholder)

    const button = document.createElement('button')
    button.className = 'icon icon--remove'
    button.setAttribute('data-action', 'click->form#removeInput')
    button.setAttribute('type-action', 'button')

    div.appendChild(input)
    div.appendChild(button)

    e.target.insertAdjacentElement('beforebegin', div)

    e.preventDefault()
  }

  removeInput (e) {
    e.target.parentNode.remove()
  }

  // ---- Logic (automatic) ---- //
  removeFlash () {
    this.flashTargets.forEach(flash => {
      flash.remove()
    })
  }

  removeFieldError (input) {
    input.parentNode.querySelectorAll('.field_with_errors').forEach(element => {
      element.classList.remove('field_with_errors')
    })

    const message = input.parentNode.nextElementSibling
    if (!!message && message.classList.contains('error')) message.remove()
  }

  toggleFilled (input) {
    input.classList.toggle('filled', !!input.value)
  }

  applyFormat (input) {
    const format = input.dataset.formatter
    if (!format || this.isUserDeleting(input)) return

    const result = Formatter.apply(format, input.value)
    const length = result.length

    input.value = result.substr(0, input.maxLength || length)

    if (input.type === 'text') {
      input.focus()
      input.setSelectionRange(length, length)
    }
  }

  applyBlurFormat (input) {
    const format = input.dataset.blurFormatter
    if (format) input.value = Formatter.apply(format, input.value)
  }

  enforceMaxLength (input) {
    const maxlength = input.dataset.maxlength
    const value = input.value

    if (!!maxlength && value.length >= maxlength) input.value = value.substr(0, maxlength)
  }

  updateCounter (input) {
    const counter = input.dataset.counter
    const length = input.value.length
    const maxlength = input.dataset.maxlength

    if (counter) document.getElementById(`${input.id}_counter`).textContent = `${length}/${maxlength}`
  }

  validateField (input) {
    const value = input.value
    const required = input.dataset.required
    const validator = input.dataset.validator || input.dataset.blurFormatter || input.dataset.formatter
    let valid = true

    if (Text.isEmpty(value)) {
      valid = !required
    } else if (validator) {
      valid = Validator.assert(validator, value)
    }

    if (valid) {
      this.removeFieldError(input)
    } else {
      this.showFieldErrors(input)
    }

    return valid
  }

  // ---- Logic (navigation) ---- //
  navigate (index) {
    const stale = this.currentStep
    this.index = index
    const fresh = this.currentStep

    stale.classList.add('hidden')
    fresh.classList.remove('hidden')

    this.titleTarget.textContent = fresh.dataset.title
    if (this.hasCounterTarget) this.counterTarget.textContent = `${fresh.dataset.index}/${this.counterMax}`
    if (this.hasProgressTarget) {
      this.progressTarget.dataset.progress = fresh.dataset.index
    }
    if (this.hasPreviousButtonTarget) this.previousButtonTarget.classList.toggle('invisible', this.index === 0)
  }

  validate () {
    const step = this.currentStep
    const fields = Array.from(step.querySelectorAll('input'))

    fields.forEach(field => this.validateField(field))
    return !fields.some(field => field.classList.contains('field_with_errors'))
  }

  // ---- Support ---- //
  isUserDeleting (input) {
    const previous = input.dataset.previousValue || ''
    return previous.length > input.value.length
  }

  showFieldErrors (input) {
    input.classList.add('field_with_errors')
    if (input.nextElementSibling) input.nextElementSibling.classList.add('field_with_errors')
  }

  // ---- Getters & Setters --- //
  get currentStep () {
    return this.stepTargets[this.index]
  }

  get isMultiStep () {
    return this.hasStepTarget
  }
}
