import ParentController from './parent_controller'
import Text from '../../../lib/text'

export default class extends ParentController {
  static targets = ['journey']

  /* == Actions == */
  next () {
    setTimeout(() => {
      this.autofocus()
      this.display()
    }, 10)
  }

  display () {
    this.currentTrack.querySelectorAll('[data-display-field]').forEach(target => {
      const source = this.findSource(target.dataset)
      this.pasteValue(target, this.copyValue(source))
    })
  }

  /* == Logic == */
  autofocus () {
    setTimeout(() => {
      const element = this.currentTrack.querySelector('[data-auto-focus]')
      if (element) element.focus()
    }, 10)
  }

  findSource (data) {
    let selector = `[name="${data.displayField}"]`

    if (data.displayField.endsWith('_id]')) selector += ':checked'
    if (data.displayMultiple === 'true') selector += ':checked'

    return document.querySelector(selector)
  }

  copyValue (element) {
    if (element.tagName === 'SPAN') {
      return element.textContent
    } else if (element.tagName === 'INPUT' && element.type === 'radio') {
      return element.dataset.text || element.dataset.value
    } else {
      return element.value
    }
  }

  pasteValue (element, value, force = true) {
    const prefix = element.dataset.displayPrefix || ''
    element.textContent = `${prefix}${value}`

    const separator = element.dataset.displaySeparator
    if (separator && Text.isPresent(value) && force) {
      const original = this.copyValue(element.previousElementSibling)
      this.pasteValue(element.previousElementSibling, `${original}${separator}`, false)
    }
  }
}
