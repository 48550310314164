import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['label', 'input', 'uploadNew']

  toggleLabel () {
    const sliceFrom = this.inputTarget.value.indexOf('fakepath') + 9
    this.labelTarget.innerText = this.inputTarget.value.slice(sliceFrom, -4)
    this.labelTarget.classList.add('txt-s20')
    this.uploadNewTarget.innerText = 'Escolher outro arquivo'
    this.uploadNewTarget.classList.add('mt-12')
  }
}
