import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['section']

  connect () {
    this.dirty = false

    this.initAnimation()
    this.initEventHandlers()
  }

  // --- Life Cycle ---- //
  initAnimation () {
    setTimeout(() => {
      this.element.classList.remove('modal--initial')
    }, 10) // Delay the removal to ensure the modal animates correctly;

    setTimeout(() => {
      this.disableWindowScrolling()
    }, 310) // This is the css animation time
  }

  initEventHandlers () {
    document.addEventListener('keyup', this.handleEscapeKey.bind(this))
    this.element.addEventListener('input', () => { this.dirty = true })
    this.element.addEventListener('submit', () => { this.dirty = false })
  }

  // ---- Actions ---- //
  close () {
    if (this.dirty) {
      return this.openDismissDialog()
    }
    this.element.classList.add('modal--closed')
    this.enableWindowScrolling()

    setTimeout(() => {
      this.element.remove()
    }, 315)
  }

  previousSection (e) {
    const current = e.target.closest('[data-target=\'modal.section\']')
    const back = this.sectionTargets[this.sectionTargets.indexOf(current) - 1]

    const title = current.dataset.dialogTitle
    if (title) this.setTitle(title)

    current.classList.add('hidden')
    back.classList.remove('hidden')
  }

  nextSection (e) {
    const skip = e.currentTarget.dataset.sectionSkip || 0
    const current = e.target.closest('[data-target=\'modal.section\']')
    const next = this.sectionTargets[this.sectionTargets.indexOf(current) + 1 + Number(skip)]

    const title = current.dataset.dialogTitle
    if (title) this.setTitle(title)

    current.classList.add('hidden')
    next.classList.remove('hidden')
  }

  // ---- Event Handlers ---- //
  handleEscapeKey (e) {
    if (e.key === 'Escape') this.close()
  }

  // ---- Support ---- //
  disableWindowScrolling () {
    if (this.element.classList.contains('modal--closed')) return

    this.y = window.scrollY
    this.x = window.scrollX

    document.body.classList.add('noscroll')
  }

  enableWindowScrolling () {
    document.body.classList.remove('noscroll')
    window.scrollTo(this.x, this.y)
  }

  confirmDismiss () {
    document.querySelector('.confirmation').remove()
    this.dirty = false
    this.close()
  }

  cancelDismiss () {
    document.querySelector('.confirmation').remove()
  }

  openDismissDialog () {
    this.element.insertAdjacentElement('beforeend', this.dismissDialog())
  }

  dismissDialog () {
    const div = document.createElement('div')
    div.className = 'confirmation modal modal--minimal'

    const overlay = document.createElement('div')
    overlay.className = 'modal__overlay'
    overlay.setAttribute('data-action', 'click->modal#cancelDismiss')
    div.appendChild(overlay)

    const content = document.createElement('div')
    content.className = 'confirmation__content'
    div.appendChild(content)

    const message = document.createElement('p')
    message.className = 'confirmation__message'
    message.textContent = 'Descartar alterações?'
    content.appendChild(message)

    const actions = document.createElement('div')
    actions.className = 'confirmation__actions'
    content.appendChild(actions)

    const cancel = document.createElement('button')
    cancel.className = 'confirmation__cancel'
    cancel.type = 'button'
    cancel.title = 'Cancelar'
    cancel.textContent = 'Cancelar'
    cancel.setAttribute('data-action', 'modal#cancelDismiss')
    actions.appendChild(cancel)

    const confirm = document.createElement('button')
    confirm.className = 'confirmation__accept'
    confirm.type = 'button'
    confirm.title = 'Ok'
    confirm.textContent = 'Descartar'
    confirm.setAttribute('data-action', 'modal#confirmDismiss')
    actions.appendChild(confirm)

    return div
  }

  set dirty (value) {
    this.data.set('dirty', value)
  }

  get dirty () {
    return this.data.get('dirty') === 'true'
  }
}
