import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect () {
    this.observer = new IntersectionObserver(this.toggle.bind(this), { threshold: this.threshold() })
    this.observer.observe(this.target())
  }

  disconnect () {
    this.observer.unobserve(this.target())
  }

  toggle (entries, observer) {
    const entry = entries[0]
    const isIntersecting = this.element.dataset.intersectorNotIntersecting ? !entry.isIntersecting : entry.isIntersecting
    this.element.classList.toggle(this.class(), isIntersecting)
  }

  target () {
    return document.querySelector(this.element.dataset.intersectorTarget)
  }

  threshold () {
    return this.element.dataset.intersectorThreshold || 1.0
  }

  class () {
    return this.element.dataset.intersectorCssClass
  }
}
