import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['toggable']
  static classes = ['active']
  static values = { lockScrolling: Boolean, lockScrollingDelay: Number, type: String }

  /* ==== Actions ==== */
  off () {
    this.toggableTargets.forEach(element => {
      if (this.isDisableToggle) {
        element.disabled = true
      } else {
        element.classList.remove(this.activeClass)
      }
    })
  }

  on () {
    this.toggableTargets.forEach(element => {
      if (this.isDisableToggle) {
        element.disabled = false
      } else {
        element.classList.add(this.activeClass)
      }
    })
  }

  toggle () {
    this.lockScrollingValue ? this.toggleAndLock() : this.toggleOnly()
  }

  /* ==== Support ===== */
  toggleOnly () {
    this.toggableTargets.forEach(element => {
      if (this.isDisableToggle) {
        element.disabled = !element.disabled
      } else {
        element.classList.toggle(this.activeClass)
      }
    })
  }

  toggleAndLock () {
    if (this.isOn) {
      this.toggleOff()
    } else {
      this.toggleOn()
    }
  }

  toggleOn () {
    this.on()

    if (this.lockScrollingValue) {
      setTimeout(() => { this.disableWindowScrolling() }, this.lockScrollingDelayValue)
    }
  }

  toggleOff () {
    this.off()

    if (this.lockScrollingValue) {
      this.enableWindowScrolling()
    }
  }

  disableWindowScrolling () {
    if (this.isOff) return

    this.y = window.scrollY
    this.x = window.scrollX

    document.body.classList.add('noscroll')
  }

  enableWindowScrolling () {
    document.body.classList.remove('noscroll')
    window.scrollTo(this.x, this.y)
  }

  get isOn () {
    if (this.isDisableToggle) {
      return !this.toggableTarget.disabled
    } else {
      return this.toggableTarget.classList.contains(this.activeClass)
    }
  }

  get isOff () {
    return !this.isOn
  }

  get isDisableToggle () {
    return this.typeValue === 'disable'
  }
}
