// Exception logging
// import Sentry from './lib/sentry'

// Rails
import Rails from './rails/rails-ujs'
import * as ActiveStorage from './rails/activestorage'
import '@hotwired/turbo-rails'

// App
import './controllers/internal'
import Polyfills from './lib/polyfills'
import ImageLazyLoading from './lib/image_lazy_loading'
import Invitations from './elements/invitations'
import MinimalAnalytics from './lib/minimal_analytics'

//  Start everything that needs to be started
// Sentry.start()
Rails.start()
ActiveStorage.start()
Polyfills.start()
ImageLazyLoading.start()
Invitations.start()
MinimalAnalytics.start()
