import { Controller } from '@hotwired/stimulus'
import Rails from '../../rails/rails-ujs'
import Util from '../../lib/util'

export default class extends Controller {
  static targets = ['moz', 'title', 'identifier', 'category']

  connect () {
    this.debouncedFetch = Util.debounce(() => {
      const queryString = new URLSearchParams({
        id: this.mozTarget.value,
        title: this.titleTarget.value,
        identifier: this.identifierTarget.value,
        category: this.categoryTarget.value
      }).toString()

      Rails.ajax({
        type: 'GET',
        url: `${this.data.get('url')}/?${queryString}`,
        dataType: 'json'
      })
    }, 500)
  }

  display () {
    if (this.mozTarget.value) this.debouncedFetch()
  }
}
