import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['message', 'content']

  connect () {
    this.message = this.messageTarget.dataset.message
  }

  toggle () {
    this.contentTarget.classList.toggle('hidden')

    const message = this.messageTarget.textContent
    this.messageTarget.textContent = this.message
    this.message = message
  }
}
