import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  clone () {
    const clone = this.element.cloneNode(true)
    clone.querySelector('h2').textContent += ' cópia'

    this.element.insertAdjacentElement('afterEnd', clone)
  }

  remove () {
    this.element.remove()
  }
}
