import { Controller } from '@hotwired/stimulus'
import Social from '../../lib/social'
import Util from '../../lib/util'
import Rails from '../../rails/rails-ujs'

export default class extends Controller {
  static targets = ['proceedButton', 'url', 'message', 'clipboard']

  /* == Life cycle == */
  initialize () {
    this.prepareImageForSharing()
  }

  connect () {
    this.debouncedSubmit = Util.debounce((e) => {
      e.form.querySelector('input[type=\'submit\']').click()
    }, 1000)
  }

  /* == Actions == */
  dirty (e) {
    e.target.form.dataset.status = 'dirty'
    this.allowSharing = false
  }

  save (e) {
    this.debouncedSubmit(e.target)
  }

  changesSaved (e) {
    if (e.target.form.dataset.status === 'valid') this.allowSharing = true
  }

  chooseSharingMode (e) {
    if (Util.deviceCanShare()) {
      this.prepareForNativeSharing(e)
    } else {
      this.prepareForAppSharing(e)
    }
  }

  whatsapp (e) {
    let text = this.message
    text = `${text}\n\n${this.formattedUrl}`

    e.target.href = Social.whatsapp(text)
  }

  facebook (e) {
    const url = this.formattedUrl
    e.target.href = Social.facebook(url)
  }

  /* == Logic == */
  prepareImageForSharing () {
    if (!this.imageUrl || !Util.deviceCanShareFiles()) return

    this.allowSharing = false
    fetch(this.imageUrl).then(res => {
      if (!res.ok) {
        throw Error(res.statusText)
      } else {
        return res.blob()
      }
    }).then(blob => {
      this.imageFile = new File([blob], 'dot.png', { type: 'image/png' })
    }).finally(() => {
      this.allowSharing = true
    })
  }

  prepareForNativeSharing (e) {
    try {
      const shareData = {
        title: this.title,
        text: this.message,
        url: this.formattedUrl,
        files: this.imageFile ? [this.imageFile] : []
      }

      navigator.share(shareData).then((resolve, reject) => {
        // TODO: Log this result in analytics;
      }).catch(error => {
        if (error.name === 'AbortError') {
          // TODO: Log this error in analytics;
        } else {
          throw error
        }
      })

      e.stopImmediatePropagation()
      Rails.fire(this.element.closest('.modal'), 'close')
    } catch (e) {
      this.prepareForAppSharing()
    }
  }

  prepareForAppSharing () {
    this.clipboardTarget.dataset.clipboardText = this.formattedUrl
  }

  /* == Getter and Setters == */
  set allowSharing (value) {
    this.proceedButtonTarget.disabled = !value
  }

  get formattedUrl () {
    return `${this.urlTarget.dataset.prefix}${this.urlTarget.value}`
  }

  get message () {
    return this.messageTarget.value.replace(/http.*/, '')
  }

  get title () {
    return this.data.get('title')
  }

  get imageUrl () {
    /*
     * User is about to share his website, however its image is to big (height, not kbs) for that.
     * If user has paid for an invitation, use its image for sharing, otherwise rely on opengraph for the preview.
     *
     * This replace is a hack because CORS is broken when going through cloudflare
     */
    return (this.data.get('sharableImageUrl') || '').replace('https://', 'https://s3.amazonaws.com/')
  }
}
