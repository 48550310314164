import ParentController from './parent_controller'
import Formatter from '../../../lib/formatter'
import Text from '../../../lib/text'
import Validator from '../../../lib/validator'
import Rails from '../../../rails/rails-ujs'

export default class extends ParentController {
  static targets = ['journey', 'flash']

  connect () {
  }

  /* == Actions == */
  input (e) {
    const input = e.currentTarget

    this.valid = true
    this.removeFlash()
    this.removeFieldError(input)
    this.toggleFilled(input)
    this.applyFormat(input)
    this.enforceMaxLength(input)
    this.updateCounter(input)

    input.dataset.previousValue = input.value
  }

  change (e) {
    const input = e.currentTarget

    this.removeFlash()
    this.removeFieldError(input)
    this.toggleFilled(input)
    this.applyFormat(input)
    this.enforceMaxLength(input)

    input.dataset.previousValue = input.value
  }

  blur (e) {
    const input = e.currentTarget

    this.applyBlurFormat(input)
    this.validateField(input)
  }

  next (e) {
    const inputs = Array.from(this.validateable.querySelectorAll('input, textarea, select'))
    this.valid = inputs.reduce((valid, input) => this.validateField(input) && valid, true)

    if (!this.valid) {
      document.querySelector('.field_with_errors').focus()

      e.stopImmediatePropagation()
      e.preventDefault()
    }
  }

  save (e) {
    this.next(e)
  }

  /* == Logic == */
  removeFlash () {
    this.flashTargets.forEach(flash => {
      flash.remove()
    })
  }

  removeFieldError (input) {
    input.classList.remove('field_with_errors')
    if (input.nextElementSibling && input.nextElementSibling.tagName === 'LABEL') input.nextElementSibling.classList.remove('field_with_errors')
  }

  toggleFilled (input) {
    input.classList.toggle('dynaform__field--filled', !!input.value)
  }

  applyFormat (input) {
    const format = input.dataset.formatter
    if (!format || this.isUserDeleting(input)) return

    const result = Formatter.apply(format, input.value)
    const length = result.length

    input.value = result.substr(0, input.maxLength || length)

    if (input.type === 'text') {
      input.focus()
      input.setSelectionRange(length, length)
    }
  }

  applyBlurFormat (input) {
    const format = input.dataset.blurFormatter
    if (format) input.value = Formatter.apply(format, input.value)
  }

  enforceMaxLength (input) {
    const maxlength = input.dataset.maxlength
    const value = input.value

    if (!!maxlength && value.length >= maxlength) input.value = value.substr(0, maxlength)
  }

  updateCounter (input) {
    const counter = document.getElementById(`${input.id}_counter`)
    const length = input.value.length
    const maxlength = input.maxLength

    if (counter) counter.textContent = `${length}/${maxlength}`
  }

  validateField (input) {
    if (input.disabled) return true

    const value = input.value
    const required = input.dataset.required
    const validator = input.dataset.validator || input.dataset.blurFormatter || input.dataset.formatter
    let valid = true

    if (Text.isEmpty(value)) {
      valid = !required || required === 'false'
    } else if (validator) {
      valid = Validator.assert(validator, value)
    }

    if (valid) {
      this.removeFieldError(input)
    } else {
      this.showFieldErrors(input)
    }

    return valid
  }

  internationalPayment (e) {
    const input = e.currentTarget
    const cpfInput = document.querySelector('#payment_cpf_cnpj')
    const phoneInput = document.querySelector('#payment_phone')

    if (input.checked) {
      this.removeFieldError(cpfInput)
      cpfInput.value = ''
      cpfInput.removeAttribute('data-required')
      cpfInput.setAttribute('disabled', true)
      phoneInput.setAttribute('data-formatter', 'number')
    } else {
      cpfInput.removeAttribute('disabled')
      cpfInput.setAttribute('data-required', true)
      phoneInput.setAttribute('data-formatter', 'phone')
      phoneInput.value = null
    }
  }

  /* == Support == */
  isUserDeleting (input) {
    const previous = input.dataset.previousValue || ''
    return previous.length > input.value.length
  }

  showFieldErrors (input) {
    input.classList.add('field_with_errors')
    if (input.nextElementSibling && input.nextElementSibling.tagName === 'LABEL') input.nextElementSibling.classList.add('field_with_errors')
  }

  /* == Getters & Setters == */
  get validateable () {
    return this.hasJourneyTarget ? this.currentTrack : this.element
  }

  get valid () {
    return this.validateable.dataset.valid === 'true'
  }

  set valid (value) {
    this.validateable.setAttribute('data-valid', value)
    Rails.fire(this.validateable, 'validated')
  }
}
