import { Controller } from '@hotwired/stimulus'
import Rails from '../../rails/rails-ujs'

export default class extends Controller {
  static targets = ['input', 'trigger', 'content', 'error']
  static values = { 'trigger': String, 'clearError': Boolean, 'clearSuccess': Boolean, 'submitOnConnect': Boolean, 'replaceMode': String, clearInputOnError: Boolean }

  /* ==== Life Cycle ==== */
  connect () {
    this.inputTarget.dataset.action = `${this.inputTarget.dataset.action} input->remote-input#input`
    if (this.submitOnConnectValue) this.fetch()
  }

  /* ==== Actions ==== */
  fetch () {
    this.disableTrigger()
    this.status = 'sent'

    Rails.ajax({
      url: this.url,
      type: 'POST',
      data: this.queryString(),
      success: (_response, _status, xhr) => {
        this.success = xhr.response
      },
      error: (_response, _status, xhr) => {
        this.error = xhr.response
      },
      complete: () => {
        this.enableTrigger()
      }
    })
  }

  input () {
    this.error = null
    this.success = null
    this.status = 'none'

    const { value, maxLength } = this.inputTarget
    if (this.isAutoTrigger && value.length === maxLength) {
      this.inputTarget.blur()
      this.fetch()
    }
  }

  /* ==== Support ==== */
  disableTrigger () {
    this.toggleTrigger(false)
  }

  enableTrigger () {
    this.toggleTrigger(true)
  }

  toggleTrigger (force) {
    if (this.isAutoTrigger) {
      this.inputTarget.blur()
      this.inputTarget.disabled = !force
      this.inputTarget.classList.toggle('disabled', !force)
    }

    if (this.hasTriggerTarget) {
      this.triggerTargets.forEach(triggerInput => {
        triggerInput.disabled = !force
        triggerInput.classList.toggle('disabled', !force)
      })
    }
  }

  queryString () {
    const params = []
    this.inputTargets.map((target) => params.push(`${target.name}=${target.value}`))
    return params.join('&')
  }

  /* ==== Getters and Setters ==== */
  get url () {
    return this.data.get('url')
  }

  get isAutoTrigger () {
    return this.triggerValue === 'automatic'
  }

  get clearErrorOnInput () {
    return this.hasClearErrorValue ? this.clearErrorValue : true
  }

  get clearSuccessOnInput () {
    return this.hasClearSuccessValue ? this.clearSuccessValue : false
  }

  set success (html) {
    if (html) {
      this.contentTarget[this.replaceMode] = html
      this.status = 'success'
    } else if (this.clearSuccessOnInput) {
      this.contentTarget.innerHTML = ''
    }
  }

  set error (html) {
    if (html) {
      this.errorTarget.innerHTML = html
      this.status = 'error'
      if (this.clearInputOnErrorValue) {
        this.inputTarget.value = ''
      }
    } else if (this.clearErrorOnInput) {
      this.errorTarget.innerHTML = ''
    }
  }

  set status (value) {
    this.element.setAttribute('data-ajax', value)
  }

  get replaceMode () {
    return this.replaceModeValue || 'innerHTML'
  }
}
