import { Controller } from '@hotwired/stimulus'
import Dom from '../../lib/dom'
import Rails from '../../rails/rails-ujs'

export default class extends Controller {
  static targets = ['input']
  static classes = ['loader']
  static values = { 'remote': Boolean, 'minimum': Number }

  increase () {
    if (this.value < this.maximum) {
      this.value++
      Rails.fire(this.inputTarget, 'input')
    }
  }

  decrease () {
    if (this.value > this.minimumValue) {
      this.value--
      Rails.fire(this.inputTarget, 'input')
    }
  }

  get maximum () {
    const length = Number(this.inputTarget.maxLength)
    return isNaN(length) || length <= 0 ? Number.MAX_SAFE_INTEGER : Math.pow(10, length) - 1
  }

  get minimum () {
    return this.hasMinimumValue ? this.minimumValue : 0
  }

  get value () {
    const value = Dom.get(this.inputTarget)
    return value ? Number(value) : 0
  }

  set value (value) {
    Number(Dom.set(this.inputTarget, value))

    if (this.remoteValue) {
      this.element.classList.add(this.loaderClass)
      setTimeout(() => Rails.fire(this.element.closest('form'), 'submit'), 0)
    }
  }
}
