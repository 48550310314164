import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  initialize () {
    this.setupCallback = this.setup.bind(this)
    this.beginCallback = this.begin.bind(this)
    this.progressCallback = this.progress.bind(this)
    this.errorCallback = this.error.bind(this)
    this.endCallback = this.end.bind(this)
  }

  connect () {
    document.addEventListener('direct-upload:initialize', this.setupCallback)
    document.addEventListener('direct-upload:start', this.beginCallback)
    document.addEventListener('direct-upload:progress', this.progressCallback)
    document.addEventListener('direct-upload-error', this.errorCallback)
    document.addEventListener('direct-upload:end', this.endCallback)
  }

  disconnect () {
    document.removeEventListener('direct-upload:initialize', this.setupCallback)
    document.removeEventListener('direct-upload:start', this.beginCallback)
    document.removeEventListener('direct-upload:progress', this.progressCallback)
    document.removeEventListener('direct-upload-error', this.errorCallback)
    document.removeEventListener('direct-upload:end', this.endCallback)
  }

  setup (event) {
    const id = event.detail.id

    const directUpload = document.createElement('div')
    directUpload.classList.add('direct-upload')

    const directUploadMessage = document.createElement('span')
    directUploadMessage.classList.add('direct-upload__message')
    directUploadMessage.id = 'direct-upload-message-' + id
    directUploadMessage.textContent = this.message(event)
    directUpload.appendChild(directUploadMessage)

    const directUploadBar = document.createElement('div')
    directUploadBar.classList.add('direct-upload__bar')
    directUploadBar.id = 'direct-upload-' + id
    directUpload.appendChild(directUploadBar)

    const directUploadProgress = document.createElement('div')
    directUploadProgress.classList.add('direct-upload__progress')
    directUploadProgress.id = 'direct-upload-progress-' + id
    directUploadBar.appendChild(directUploadProgress)

    document.body.insertAdjacentElement('beforeEnd', directUpload)
  }

  begin (event) {
    const { id } = event.detail
    const element = document.getElementById('direct-upload-' + id)
    element.classList.remove('direct-upload--pending')
  }

  progress (event) {
    const { id, progress } = event.detail
    const element = document.getElementById('direct-upload-' + id)
    element.style.width = (progress + '%')
  }

  error (event) {
    const { id, error } = event.detail

    const progress = document.getElementById('direct-upload-progress' + id)
    progress.classList.add('direct-upload--error')

    const message = document.getElementById('direct-upload-message' + id)
    progress.classList.add('direct-upload--error')
    message.textContent = error
  }

  end (event) {
    const { id } = event.detail
    const element = document.getElementById('direct-upload-' + id)
    element.classList.add('direct-upload--complete')

    document.querySelector('.direct-upload__message').textContent = 'Salvando...'
  }

  message (event) {
    const accept = event.target.accept
    if (accept.includes('pdf')) {
      return 'Enviando PDF...'
    } else if (accept.includes('image')) {
      return 'Enviando imagem...'
    } else {
      return 'Enviando arquivo...'
    }
  }
}
