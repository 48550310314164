import { Controller } from '@hotwired/stimulus'
import Text from '../../lib/text'
import Util from '../../lib/util'
import Social from '../../lib/social'
import Rails from '../../rails/rails-ujs'

export default class extends Controller {
  static targets = ['message', 'clipboard', 'whatsapp']

  start (e) {
    if (Util.deviceCanShare()) {
      this.prepareForNativeSharing(e)
    } else {
      this.prepareForAppSharing()
    }
  }

  /* == Logic == */
  prepareForNativeSharing (e) {
    try {
      navigator.share({ text: this.message }).then((resolve, reject) => {
        // TODO: Log this result in analytics;
      }).catch(error => {
        if (error.name === 'AbortError') {
          // TODO: Log this error in analytics;
        } else {
          throw error
        }
      })

      e.stopImmediatePropagation()
      Rails.fire(this.element.closest('.modal'), 'close')
    } catch (e) {
      this.prepareForAppSharing()
    }
  }

  prepareForAppSharing () {
    this.clipboardTarget.dataset.clipboardText = this.message
    this.whatsappTarget.href = Social.whatsapp(this.message)
  }

  get message () {
    const value = this.messageTarget.value
    return Text.isPresent(value) ? value : this.messageTarget.placeholder
  }
}
