import { Controller } from '@hotwired/stimulus'
import Text from '../../lib/text'
import Util from '../../lib/util'

export default class extends Controller {
  static targets = ['filter', 'list', 'item']

  connect () {
    this.debouncedFilter = Util.debounce(() => {
      this.updateList(this.filterItems())
    }, 300)

    this.items = this.itemTargets
  }

  filter () {
    this.debouncedFilter()
  }

  updateList (results) {
    this.items.forEach((element, index) => {
      element.classList.toggle('hidden', !results[index])
    })
  }

  filterItems () {
    return this.items.map(element => (Text.parameterize(element.dataset.filters).includes(this.treatedFilter)))
  }

  get treatedFilter () {
    return Text.parameterize(this.filterTarget.value)
  }
}
