import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['content', 'title']

  tab (e) {
    const target = e.target.dataset.tab

    this.titleTargets.forEach(title => {
      title.classList.toggle('tabs__title--active', title.dataset.tab === target)
    })

    this.contentTargets.forEach(content => {
      content.classList.toggle('tabs__tab--active', content.dataset.tab === target)
    })
  }
}
