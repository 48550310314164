import { Controller } from '@hotwired/stimulus'
import Text from '../../lib/text'
import Dom from '../../lib/dom'
import Rails from '../../rails/rails-ujs'

export default class extends Controller {
  static targets = ['name', 'number', 'month', 'year', 'cvv', 'hash']

  connect () {
    this.blockSensitiveParameters()
  }

  generate (e) {
    if (!this.validate()) return
    Dom.fire(e.target, 'generating')

    pagarme.client.connect({ encryption_key: this.data.get('cryptoKey') })
      .then(client => client.security.encrypt(this.card))
      .then(cardHash => {
        this.hash = cardHash
        this.blockSensitiveParameters()
        Rails.fire(document.querySelector('form'), 'submit')
      })
  }

  validate () {
    const { card } = pagarme.validate({ card: this.card })
    this.brand = card.brand

    let valid = true
    valid = valid & this.validateField('card_holder_name', card, this.nameTarget)
    valid = valid & this.validateField('card_number', card, this.numberTarget, 19)
    valid = valid & this.validateField('card_expiration_date', card, this.monthTarget)
    valid = valid & this.validateField('card_expiration_date', card, this.yearTarget)
    valid = valid & this.validateField('card_cvv', card, this.cvvTarget, 3)
    return valid
  }

  validateField (key, card, input, length) {
    if (card[key] && Text.isPresent(this.card[key])) return true
    if (card[key] && !!length && this.card[key].length === length) return true

    input.classList.add('field_with_errors')
    input.nextElementSibling.classList.add('field_with_errors')
    return false
  }

  blockSensitiveParameters () {
    this.nameTarget.removeAttribute('name')
    this.numberTarget.removeAttribute('name')
    this.monthTarget.removeAttribute('name')
    this.yearTarget.removeAttribute('name')
    this.cvvTarget.removeAttribute('name')
  }

  get card () {
    const name = this.nameTarget.value
    const number = this.numberTarget.value
    const month = this.monthTarget.value
    const year = Number(this.yearTarget.value) % 2000
    const cvv = this.cvvTarget.value

    return {
      card_holder_name: name,
      card_number: number,
      card_expiration_date: `${month}/${year}`,
      card_cvv: cvv
    }
  }

  set hash (value) {
    this.hashTarget.value = value
  }

  set brand (value) {
    this.numberTarget.classList.add(value)
  }
}
