import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['companionName', 'companionLastName', 'currentGuests', 'personsList']

  /* === actions == */
  setCurrentGuests () {
    this.currentGuestsTargets.forEach((element, index) => {
      if (index === 0) {
        element.textContent = `de ${this.companions[0].name} ${this.companions[0].lastName}`
      } else {
        element.textContent = `dos ${this.companions.length} que você já adicionou`
      }
    })

    if (this.hasPersonsListTarget) this.preparePersonsList()
  }

  preparePersonsList () {
    this.activePersonList.innerHTML = ''

    this.addPersonToList(this.guest)
    this.companions.forEach((companion, index) => { this.addPersonToList(companion, index, true) })
  }

  removeGuest (e, removeFromPersonsList = true) {
    const button = e.target
    if (removeFromPersonsList) button.parentNode.remove()

    const id = Number(button.dataset.trackId)
    const track = document.querySelector(`[data-track-id='${id}']`)

    track.dataset.track = 'removed'
    track.querySelectorAll('input[type="text"]').forEach(element => element.remove())
  }

  /* === Support == */
  addPersonToList (person, index, removable = false) {
    const id = this.fetchTrackID(index)

    const div = document.createElement('div')
    div.className = 'bg-white brd-rds mb-10 mh-18 flx'

    this.appendRemoveButton(div, id, index, removable)
    this.appendPerson(div, person)
    this.appendEditButton(div, id, index, removable)

    this.activePersonList.appendChild(div)
  }

  appendRemoveButton (div, id, index, removable) {
    if (!removable) {
      const counterWeight = document.createElement('div')
      counterWeight.className = 'counter-weight w-40'
      div.appendChild(counterWeight)
      return
    }

    const remove = document.createElement('button')
    remove.className = 'w-40 bg bg-pos bg-cross-red'
    remove.type = 'button'
    remove.id = `remove_${index}`
    remove.setAttribute('data-action', 'models--rsvp#removeGuest')
    remove.setAttribute('data-track-id', id)
    div.appendChild(remove)
  }

  appendPerson (div, person) {
    const guest = document.createElement('span')
    guest.className = 'txt-s15 txt-gray txt-cnt txt--llp p18 flx--fld'
    guest.textContent = `${person.name} ${person.lastName}`
    div.appendChild(guest)
  }

  appendEditButton (div, id, index, removable) {
    if (!removable) {
      const counterWeight = document.createElement('div')
      counterWeight.className = 'counter-weight w-40'
      div.appendChild(counterWeight)
      return
    }

    const edit = document.createElement('button')
    edit.className = 'w-40 bg bg-pos bg-pencil-simple-purple'
    edit.type = 'button'
    edit.id = `edit_${index}`
    edit.setAttribute('data-action', 'dynaform--navigation#goToAndRewind')
    edit.setAttribute('data-track-id', id)
    div.appendChild(edit)
  }

  fetchTrackID (index) {
    if (index === undefined) {
      return document.querySelector('[data-track="guest_info"]').dataset.trackId
    } else {
      return document.querySelectorAll('[data-track="companion_name"]')[index].dataset.trackId
    }
  }

  /* === Getters and Setters == */
  get guest () {
    const name = this.element.querySelector('[name="event_rsvp[name]"]').value
    const lastName = this.element.querySelector('[name="event_rsvp[last_name]"]').value
    return { name, lastName }
  }

  get companions () {
    const names = Array.from(this.element.querySelectorAll('[name="event_rsvp[guest_names][]"]')).map(e => { return e.value })
    const lastNames = Array.from(this.element.querySelectorAll('[name="event_rsvp[guest_last_names][]"]')).map(e => { return e.value })

    return names.map((name, i) => {
      return { name, lastName: lastNames[i] }
    })
  }

  get activePersonList () {
    return this.personsListTargets[this.personsListTargets.length - 1]
  }

  get activeCompanionName () {
    return this.companionNameTargets[this.companionNameTargets.length - 1]
  }

  get activeCompanionLastName () {
    return this.companionLastNameTargets[this.companionLastNameTargets.length - 1]
  }
}
