import { Controller } from '@hotwired/stimulus'
import Text from '../../../lib/text'

export default class extends Controller {
  reflectVertical () {
    document.querySelectorAll('[data-controller="setout-pdf--fragment"]').forEach(element => {
      const y = element.querySelector('[name="template[fragments][][y]"]')
      const rotate = element.querySelector('[name="template[fragments][][rotate]"]')

      if (Text.isEmpty(y.value) || Text.isEmpty(rotate.value)) return

      y.value = this.height - Number(y.value)
      rotate.value = 180 - Number(rotate.value)
    })
  }

  get height () {
    const margin = 72 // Default PrawnPdf margin;
    return Number(this.data.get('height')) - margin
  }
}
