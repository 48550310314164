import { Controller } from '@hotwired/stimulus'
import Text from '../../lib/text'
import Dom from '../../lib/dom'

export default class extends Controller {
  static targets = ['name', 'number', 'month', 'year', 'cvv', 'hash', 'token', 'error']
  static values = { 'payment': Number }

  connect () {
    this.blockSensitiveParameters()
  }

  generate (e) {
    if (!this.validate()) return
    Dom.fire(e.target, 'generating')

    fetch(`/api/tuna/${this.paymentValue}/new_session`)
      .then(response => response.json())
      .then(response => {
        const token = response.sessionId
        const tokenizer = Tuna(token).tokenizator()

        tokenizer.generate(this.card)
          .then(response => this.submit(token, response.token))
          .catch(error => this.handleError(e, error))
      }).catch(error => this.handleError(e, error))
  }

  submit (token, hash) {
    this.tokenTarget.value = token
    this.hashTarget.value = hash
    this.blockSensitiveParameters()
    Dom.fire(document.querySelector('form'), 'submit', null, { bubbles: true })
  }

  handleError (e, error) {
    error = new Error(`Tuna card error at payment ${this.paymentValue}: ${error}`)
    console.log(error)

    Dom.fire(e.target, 'rejected')
    this.errorTarget.classList.remove('hidden')
  }

  validate () {
    let valid = true
    valid = valid & this.validateField(this.nameTarget)
    valid = valid & this.validateField(this.numberTarget, 19)
    valid = valid & this.validateField(this.monthTarget, 2)
    valid = valid & this.validateField(this.yearTarget, 2)
    valid = valid & this.validateField(this.cvvTarget)
    return valid
  }

  validateField (input, length) {
    let valid = true
    valid = valid && Text.isPresent(input.value)
    valid = valid && (!length || input.value.length === length)

    if (!valid) {
      input.classList.add('field_with_errors')
      input.nextElementSibling.classList.add('field_with_errors')
    }

    return valid
  }

  blockSensitiveParameters () {
    this.nameTarget.removeAttribute('name')
    this.numberTarget.removeAttribute('name')
    this.monthTarget.removeAttribute('name')
    this.yearTarget.removeAttribute('name')
    this.cvvTarget.removeAttribute('name')
  }

  get card () {
    return {
      cardHolderName: this.nameTarget.value,
      cardNumber: this.numberTarget.value.replace(/ /g, ''),
      expirationMonth: Number(this.monthTarget.value),
      expirationYear: Number(this.yearTarget.value) + 2000,
      cvv: this.cvvTarget.value,
      singleUse: true
    }
  }
}
