import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['picker']

  connect () {
    this.pickerTargets.forEach(picker => {
      this.tint(picker, picker.querySelector('input').value)
    })
  }

  // ---- Handler ---- //
  pick (e) {
    const picker = e.target.closest('div[data-color-picker-target="picker"]')
    this.tint(picker, e.target.value)
  }

  // ---- Actions ---- //
  tint (picker, color) {
    picker.style.setProperty('--bg', color)
  }
}
