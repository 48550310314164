import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['display', 'input', 'hourControl', 'minutesControl']

  /* == Actions == */
  display (e) {
    this.displayTarget.textContent = e.currentTarget.value
  }

  empty (e) {
    e.currentTarget.value = ''
  }

  hourChanged (e) {
    const value = e.target.value

    if (Number(value) > 23) {
      e.target.value = null
      return
    }

    this.updateTime()
    if (value.length === 2) this.minutesControlTarget.focus()
  }

  minutesChanged (e) {
    const value = e.target.value

    if (Number(value) > 59) {
      e.target.value = null
      return
    }

    this.updateTime()
    if (value.length === 2) this.minutesControlTarget.blur()
  }

  /* == Logic == */
  updateTime () {
    this.inputTarget.value = `${this.hour}:${this.minutes}`
  }

  /* == Getters & Setters == */
  get hour () {
    return this.hourControlTarget.value.padStart(2, '0')
  }

  get minutes () {
    return this.minutesControlTarget.value.padStart(2, '0')
  }
}
