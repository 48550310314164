import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [
    'form', 'tools', 'image', 'fragment',
    'gridControl', 'zoomControl',
    'saveButton', 'closeButton',
    'splicer',
    'x', 'y', 'width', 'height', 'skewX', 'skewY', 'rotate',
    'font', 'size', 'lineHeight', 'color', 'align', 'valign', 'letterCase', 'letterSpacing',
    'text', 'textShadowX', 'textShadowY', 'textShadowR', 'textShadowColor',
    'textStrokeColor', 'textStrokeWidth'
  ]

  connect () {
    this.fragmentTargets.forEach(fragment => {
      this.addSetoutData(fragment)
    })
  }

  save () {
    const form = this.formTarget.cloneNode(true)

    this.fragmentTargets.forEach(fragment => {
      const clone = fragment.cloneNode(true)
      this.removeSetoutData(clone)

      const input = document.createElement('input')
      input.name = 'template[fragments][][content]'
      input.value = clone.outerHTML
      form.appendChild(input)
    })

    this.saveButtonTarget.disabled = true
    this.closeButtonTarget.disabled = true

    fetch(form.action, {
      method: 'POST',
      body: new FormData(form)
    }).then(() => {
      alert('Template salvo com sucesso!')
    }).finally(() => {
      this.saveButtonTarget.disabled = false
      this.closeButtonTarget.disabled = false
    })
  }

  addFragment () {
    const fragment = document.createElement('div')
    this.addSetoutData(fragment)
    this.imageTarget.appendChild(fragment)
  }

  removeFragment () {
    if (!this.activeFragment) return

    this.activeFragment.remove()
    this.activeFragment = null
  }

  cloneFragment () {
    if (!this.activeFragment) return

    const fragment = this.activeFragment.cloneNode(true)
    fragment.dataset.y = parseInt(fragment.dataset.y) + 100

    this.imageTarget.appendChild(fragment)
  }

  editFragment (e) {
    this.activeFragment = e.currentTarget
  }

  addUrl () {
    const fragment = document.createElement('div')
    const imageRect = this.imageTarget.getBoundingClientRect()
    const scale = this.zoomControlTarget.value

    fragment.dataset.size = '21'
    fragment.dataset.text = 'festalab.com.br'
    fragment.dataset.width = '211'
    fragment.dataset.height = '60'
    fragment.style.lineHeight = '24px'
    fragment.dataset.x = (imageRect.width / scale - 211).toString()
    fragment.dataset.y = (imageRect.height / scale - 60).toString()
    fragment.dataset.font = 'Gotham-Rounded-Book'

    this.addSetoutData(fragment)
    this.imageTarget.appendChild(fragment)
  }

  addLogo (e) {
    const fragment = document.createElement('div')
    const scale = this.zoomControlTarget.value
    const logoColor = e.currentTarget.id
    const domain = e.currentTarget.dataset.domain
    const imageRect = this.imageTarget.getBoundingClientRect()
    const invitationWidth = imageRect.width / scale
    const invitationHeight = imageRect.height / scale
    const logoDimensions = {
      width: 300 * (invitationWidth / 1200),
      height: 130 * (invitationHeight / 1800)
    }
    fragment.dataset.width = logoDimensions.width.toString()
    fragment.dataset.height = logoDimensions.height.toString()
    fragment.dataset.text = `<img src="https://s3.sa-east-1.amazonaws.com/cdn.festalab.com.br/${domain}/logos/${logoColor}.png">`
    fragment.dataset.x = (invitationWidth - logoDimensions.width).toString()
    fragment.dataset.y = (invitationHeight - logoDimensions.height).toString()
    this.addSetoutData(fragment)
    this.imageTarget.appendChild(fragment)
  }

  toggleGrid (e) {
    this.fragmentTargets.forEach(fragment => {
      fragment.setAttribute('data-background', e.currentTarget.checked)
    })
  }

  zoom (e) {
    Object.assign(this.imageTarget.style, {
      transform: `scale(${e.currentTarget.value})`
    })

    this.fragmentTargets.forEach(fragment => {
      fragment.setAttribute('data-scale', e.currentTarget.value)
    })
  }

  /* == Support == */
  updateTools (element) {
    const data = element.dataset

    this.xTarget.value = data.x
    this.yTarget.value = data.y
    this.widthTarget.value = data.width
    this.heightTarget.value = data.height
    this.skewXTarget.value = data.skewX
    this.skewYTarget.value = data.skewY
    this.rotateTarget.value = data.rotate
    this.fontTarget.value = data.font
    this.sizeTarget.value = data.size
    this.lineHeightTarget.value = data.lineHeight
    this.colorTarget.value = data.color
    this.alignTarget.value = data.align
    this.valignTarget.value = data.valign
    this.letterCaseTarget.value = data.letterCase
    this.letterSpacingTarget.value = data.letterSpacing
    this.splicerTarget.value = data.splicer
    this.textTarget.value = data.text
    this.textShadowXTarget.value = data.textShadowX || ''
    this.textShadowYTarget.value = data.textShadowY || ''
    this.textShadowRTarget.value = data.textShadowR || ''
    this.textShadowColorTarget.value = data.textShadowColor || ''
    this.textStrokeColorTarget.value = data.textStrokeColor || ''
    this.textStrokeWidthTarget.value = data.textStrokeWidth || ''
  }

  toolChanged (e) {
    const tool = e.currentTarget
    this.activeFragment.setAttribute(`data-${tool.name}`, tool.value)

    // TODO: If we ever come to a point where another tool needs specific handling, we will need to rethink this part
    if (tool.name === 'splicer') {
      this.activeFragment.setAttribute('data-text', tool.options[tool.selectedIndex].getAttribute('data-text'))

      const resizerGroup = tool.options[tool.selectedIndex].getAttribute('data-text-resizer-group')
      if (resizerGroup) {
        this.activeFragment.setAttribute('data-text-resizer-group', resizerGroup)
      } else {
        this.activeFragment.removeAttribute('data-text-resizer-group')
      }
    }

    if (tool.name === 'font') {
      this.loadFont(tool.value)
    }
  }

  addSetoutData (fragment) {
    fragment.className = 'setout__fragment'
    fragment.setAttribute('data-controller', 'setout--fragment')
    fragment.setAttribute('data-setout--editor-target', 'fragment')
    fragment.setAttribute('data-action', 'click->setout--editor#editFragment')
    fragment.setAttribute('data-scale', this.zoomControlTarget.value)
    fragment.setAttribute('data-background', this.showGrid)
  }

  removeSetoutData (fragment) {
    fragment.style.background = ''
    fragment.classList.remove('setout__fragment')
    fragment.classList.remove('setout__fragment--active')

    fragment.removeAttribute('data-background')
    fragment.removeAttribute('data-scale')
    fragment.removeAttribute('data-max-height')
    fragment.removeAttribute('data-action')
    fragment.removeAttribute('data-controller')

    fragment.children[0].removeAttribute('data-original-font-size')
    fragment.children[0].removeAttribute('data-current-font-size')
  }

  loadFont (font) {
    const woff2 = `https://cdn.festalab.com.br/fonts/${font}.woff2`
    const woff = `https://cdn.festalab.com.br/fonts/${font}.woff`
    const ttf = `https://cdn.festalab.com.br/fonts/${font}.ttf`
    const style = document.createElement('style')
    style.innerHTML = `@font-face { font-family: '${font}'; font-weight: 400; font-style: normal; src: url('${woff2}') format('woff2'), url('${woff}') format('woff'), url('${ttf}') format('truetype'); }`
    document.body.appendChild(style)
  }

  /* == Getters & Setters == */
  set activeFragment (element) {
    if (this.activeFragment) {
      this.activeFragment.classList.remove('setout__fragment--active')
    }

    if (element) {
      this.fragment = element
      this.fragment.classList.add('setout__fragment--active')
      this.updateTools(element)
    }
  }

  get activeFragment () {
    return this.fragment
  }

  get showGrid () {
    return this.gridControlTarget.checked
  }
}
