import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['moreText', 'moreArrow', 'steps']

  toggleSteps () {
    this.stepsTarget.classList.toggle('hidden')
    this.moreTextTarget.classList.toggle('hidden')
    this.moreArrowTarget.classList.toggle('hidden')
  }
}
