import { Controller } from '@hotwired/stimulus'
import Dom from '../../lib/dom'

export default class extends Controller {
  static targets = ['quantity']

  static values = {
    minimumGuests: Number,
    quantityPerPackage: Number,
    quantityPerGuest: Number,
    quantityPerVirtualEvent: Number,
    round: String,
    startingQuantity: Number,
    virtualEvent: Boolean
  }

  connect () {
    setTimeout(() => this.calculate(), 0)
  }

  /* ==== Actions ==== */
  calculate () {
    if (this.virtualEventValue) {
      this.fixed()
    } else {
      this.scaling()
    }
  }

  /* ==== Support ==== */
  fixed () {
    this.quantity = this.quantityPerVirtualEventValue
  }

  scaling () {
    if (this.guests < this.minimumGuestsValue) {
      this.quantity = 0
    } else {
      const calculated = this.guests * this.quantityPerGuestValue
      const adjusted = Math.max(calculated, this.startingQuantityValue)
      const packages = adjusted / this.quantityPerPackageValue

      this.quantity = this.roundValue === 'up' ? Math.ceil(packages) : Math.floor(packages)
    }
  }

  set quantity (value) {
    Dom.set(this.quantityTarget, value)
  }

  get guests () {
    return Number(this.element.dataset.value)
  }
}
