import { Controller } from '@hotwired/stimulus'
import Rails from '../../../rails/rails-ujs'
import Parser from '../../../lib/parser'
import Enums from '../../../lib/enums'

export default class extends Controller {
  static targets = ['input', 'month', 'year', 'daysOfWeek', 'daysOfMonth', 'previous', 'next']

  connect () {
    this.today = new Date()
    this.showCurrentHeader()
    this.showCurrentWeek()
    this.showCurrentBody()
  }

  showCurrentHeader () {
    const date = this.startDate
    this.month = date.getUTCMonth()
    this.year = date.getUTCFullYear()
  }

  showCurrentWeek () {
    this.daysOfWeekTarget.innerHTML = this.buildDaysOfWeek()
  }

  showCurrentBody () {
    this.daysOfMonthTarget.innerHTML = this.buildDaysOfMonth()

    const skip = new Date(this.year, this.month, 1).getUTCDay()
    this.daysOfMonthTarget.firstElementChild.style.marginLeft = `${14.28 * skip}%`

    this.toggleControls()
  }

  toggle () {
    this.element.classList.toggle('dynaform__calendar--closed')
  }

  previous () {
    if (this.month > 0) {
      this.month--
    } else {
      this.month = 11
      this.year--
    }

    this.showCurrentBody()
  }

  next () {
    if (this.month < 11) {
      this.month++
    } else {
      this.month = 0
      this.year++
    }

    this.showCurrentBody()
  }

  chooseDate (e) {
    const day = e.target.dataset.calendarDay.padStart(2, '0')
    const month = String(this.month + 1).padStart(2, '0')
    const year = this.year

    this.inputTarget.value = this.startDate = [day, month, year].join('/')
    this.showCurrentBody()
    this.toggle()

    Rails.fire(this.inputTarget, 'input')
  }

  toggleControls () {
    if (!this.disallowPast || this.month !== this.today.getUTCMonth() || this.year !== this.today.getUTCFullYear()) {
      this.previousTarget.disabled = false
      return
    }

    this.previousTarget.disabled = true

    const today = this.today.getUTCDate() - 1
    const startDay = this.startDate.getUTCDate() - 1
    const days = this.daysOfMonthTarget.children

    for (let i = 0; i < days.length; i++) {
      if (i < today) {
        days[i].classList.add('dynaform__calendar-day--inactive')
      } else if (i === startDay) {
        days[i].classList.add('dynaform__calendar-day--active')
      }
    }
  }

  buildDaysOfWeek () {
    let tmp = ''
    Enums.weekdays.forEach((weekday) => {
      tmp += `<span>${weekday.substr(0, 3)}</span>`
    })
    return tmp
  }

  buildDaysOfMonth () {
    let tmp = ''
    for (let i = 1; i <= new Date(this.year, this.month + 1, 0).getDate(); i++) {
      tmp += `<div class="dynaform__calendar-day" data-action="click->dynaform--calendar#chooseDate" data-calendar-day="${i}">${i}</div>`
    }
    return tmp
  }

  get month () {
    return parseInt(this.data.get('month'))
  }

  set month (value) {
    this.data.set('month', value)

    const month = Enums.months[String(value + 1).padStart(2, '0')]
    this.monthTarget.textContent = month
  }

  get year () {
    return parseInt(this.data.get('year'))
  }

  set year (value) {
    this.data.set('year', value)
    this.yearTarget.textContent = value
  }

  get startDate () {
    return Parser.date(this.data.get('startDate'))
  }

  set startDate (value) {
    this.data.set('startDate', value)
  }

  get disallowPast () {
    return this.data.get('disallowPast') === 'true'
  }
}
