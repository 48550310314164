import { Controller } from '@hotwired/stimulus'

import loadImage from 'blueimp-load-image/js/load-image'
import Cropper from 'cropperjs'

export default class extends Controller {
  static targets = ['mobileX', 'mobileY', 'mobileRotate', 'mobileWidth', 'mobileHeight', 'desktopX', 'desktopY', 'desktopRotate', 'desktopWidth', 'desktopHeight', 'file', 'photo', 'resizeButton', 'chooseButton', 'restartButton', 'saveButton', 'removeButton', 'nextButton', 'imageLoader', 'error', 'subHeaderMobile', 'subHeaderDesktop', 'backButton']

  connect () {
    this.data.set('photo', this.photoTarget.src)
    this.toggleElement(this.subHeaderMobileTarget, false)
    this.toggleElement(this.subHeaderDesktopTarget, false)
  }

  change (e) {
    const file = e.target.files[0]
    if (!!file && (/image\/(jpg|jpeg|png|webp|gif)/.test(file.type))) {
      this.toggleElements('loading')
      loadImage(file, (img) => {
        this.photoTarget.src = img.src
        this.initMobileEditor()
      }, { noRevoke: true })
    } else {
      this.toggleError()
      e.target.value = ''
    }
  }

  restart () {
    this.cropper.destroy()
    this.cropper = null
    this.fileTarget.value = ''
    this.photoTarget.src = this.data.get('photo')
    this.toggleElements('restart')
  }

  edit () {
    this.toggleElements('loading')
    this.initMobileEditor()
  }

  initMobileEditor () {
    this.cropper = new Cropper(this.photoTarget, {
      aspectRatio: (1 / 1.15),
      viewMode: 1,
      autoCropArea: 1,
      cropBoxResizable: false,
      cropBoxMovable: false,
      dragMode: 'move',
      crop: (e) => {
        if (!this.exifRotate) this.exifRotate = e.detail.rotate

        this.mobileXTarget.value = e.detail.x
        this.mobileYTarget.value = e.detail.y
        this.mobileHeightTarget.value = e.detail.height
        this.mobileWidthTarget.value = e.detail.width

        // iOS is a fucking moron and all photos are rotated by default. Compensate user rotation by it
        this.mobileRotateTarget.value = e.detail.rotate - this.exifRotate
      },
      ready: () => {
        this.toggleElements('editing')
      }
    })
  }

  initDesktopEditor () {
    this.cropper.destroy()
    this.cropper = new Cropper(this.photoTarget, {
      aspectRatio: (16 / 9),
      viewMode: 1,
      autoCropArea: 1,
      cropBoxResizable: false,
      cropBoxMovable: false,
      dragMode: 'move',
      crop: (e) => {
        if (!this.exifRotate) this.exifRotate = e.detail.rotate

        this.desktopXTarget.value = e.detail.x
        this.desktopYTarget.value = e.detail.y
        this.desktopHeightTarget.value = e.detail.height
        this.desktopWidthTarget.value = e.detail.width

        // iOS is a fucking moron and all photos are rotated by default. Compensate user rotation by it
        this.desktopRotateTarget.value = e.detail.rotate - this.exifRotate
      },
      ready: () => {
        this.toggleElements('next')
      }
    })
  }

  toggleElements (status) {
    switch (status) {
      case 'loading':
        this.element.classList.add('photo-editor--editing')
        this.toggleElement(this.photoTarget, false, 'invisible')
        this.toggleElement(this.chooseButtonTarget, false)
        if (this.hasResizeButtonTarget) this.toggleElement(this.resizeButtonTarget, false)
        if (this.hasRemoveButtonTarget) this.toggleElement(this.removeButtonTarget, false)
        break
      case 'editing':
        this.toggleElement(this.photoTarget, true, 'invisible')
        this.toggleElement(this.nextButtonTarget, true)
        this.toggleElement(this.subHeaderMobileTarget, true)
        this.toggleElement(this.restartButtonTarget, true)
        break
      case 'next':
        this.toggleElement(this.photoTarget, true, 'invisible')
        this.toggleElement(this.nextButtonTarget, false)
        this.toggleElement(this.subHeaderMobileTarget, false)
        this.toggleElement(this.subHeaderDesktopTarget, true)
        this.toggleElement(this.saveButtonTarget, true)
        break
      default:
        this.element.classList.remove('photo-editor--editing')
        this.toggleElement(this.restartButtonTarget, false)
        this.toggleElement(this.nextButtonTarget, false)
        this.toggleElement(this.chooseButtonTarget, true)
        this.toggleElement(this.subHeaderMobileTarget, false)
        this.toggleElement(this.subHeaderDesktopTarget, false)
        this.toggleElement(this.saveButtonTarget, false)
        if (this.hasResizeButtonTarget) this.toggleElement(this.resizeButtonTarget, this.hasPhoto())
        if (this.hasRemoveButtonTarget) this.toggleElement(this.removeButtonTarget, this.hasPhoto())
    }
  }

  nextDevice () {
    this.toggleElements('loading')
    this.initDesktopEditor()
  }

  toggleElement (element, force, klass = 'hidden') {
    element.classList.toggle(klass, !force)
  }

  toggleError () {
    this.errorTarget.classList.toggle('hidden')
  }

  hasPhoto () {
    return this.data.get('hasPhoto') === 'true'
  }
}
