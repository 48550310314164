import { Controller } from '@hotwired/stimulus'
import Formatter from '../../../lib/formatter'
import Numeric from '../../../lib/numeric'
import Rails from '../../../rails/rails-ujs'

export default class extends Controller {
  static targets = [
    'price', 'width', 'height', 'length',
    'priceDisplay', 'descriptionDisplay', 'imageDisplay'
  ]

  preview (e) {
    const data = e.target.selectedOptions[0].dataset

    this.price = data.price
    this.width = data.width
    this.height = data.height
    this.length = data.length
  }

  set price (value) {
    this.priceTarget.value = value
    this.priceDisplayTarget.textContent = Formatter.currency(value)
  }

  set width (value) {
    this.widthTarget.value = Numeric.format(value / 10.0)
    Rails.fire(this.widthTarget, 'input')
  }

  set height (value) {
    this.heightTarget.value = Numeric.format(value / 10.0)
    Rails.fire(this.heightTarget, 'input')
  }

  set length (value) {
    this.lengthTarget.value = Numeric.format(value / 10.0)
    Rails.fire(this.lengthTarget, 'input')
  }
}
