import Draggable from './lib/draggable'
import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['text']

  /* == Life Cycle == */
  initialize () {
    this.initText()
    this.initWrapper()
    this.initInteraction()
    this.initObserver()

    this.updateLegacyFragment()
    this.updateBackground()
  }

  connect () {
    this.observer.observe(this.element, { attributes: true, attributeOldValue: true })
  }

  disconnect () {
    this.observer.disconnect(this.element)
  }

  /* == Initialization == */
  initWrapper () {
    this.element.setAttribute('style', 'position:absolute;top:0;left:0;display:flex;')

    this.x = this.x || 0
    this.y = this.y || 0
    this.skewX = this.skewX || 0
    this.skewY = this.skewY || 0
    this.rotate = this.rotate || 0
    this.width = this.width || 400
    this.height = this.height || 150
    this.align = this.align || 'center'
    this.valign = this.valign || 'center'

    this.updateWrapperStyle()
  }

  initText () {
    if (!this.hasTextTarget) {
      const span = document.createElement('span')
      span.setAttribute('data-setout--fragment-target', 'text')
      span.setAttribute('data-allow-font-resize', 'true')
      span.setAttribute('style', '')
      this.element.appendChild(span)
    }

    this.font = this.font || 'Gotham-Rounded-Medium'
    this.size = this.size || 50
    this.lineHeight = this.lineHeight || this.size
    this.color = this.color || '000000'
    this.letterCase = this.letterCase || 'none'
    this.letterSpacing = this.letterSpacing || 0
    this.text = this.textTarget.innerHTML || this.text || 'Insira seu texto'
    this.textShadowColor = this.textShadowColor || 'FFFFFFFF'
    this.textStrokeColor = this.textStrokeColor || 'FFFFFFFF'

    this.updateTextStyle()
    this.updateTextContent()
  }

  initInteraction () {
    this.draggable = new Draggable(this.element, this.drag.bind(this))
  }

  initObserver () {
    this.observer = new MutationObserver(this.changed.bind(this))
    this.element.addEventListener('mouseup', this.resize.bind(this))
  }

  /* == Interaction == */
  drag (event) {
    this.x += event.dx / this.scale
    this.y += event.dy / this.scale

    this.updateWrapperStyle()
  }

  resize (event) {
    if (this.skewX || this.skewY || this.rotate) return // TODO

    const rect = this.element.getBoundingClientRect()
    this.width = rect.width / this.scale
    this.height = rect.height / this.scale

    this.updateWrapperStyle()
  }

  changed (mutations) {
    const changesPending = mutations.some(mutation => {
      // Ignore mutations that aren't in a data attribute
      const name = mutation.attributeName
      if (name.indexOf('data-') === -1) return false

      // Ignore mutations that didn't change a value
      const currentValue = this.element.getAttribute(name)
      if (currentValue === mutation.oldValue) return false

      // Remove empty attributes
      if (!currentValue) this.element.removeAttribute(name)

      return true
    })

    // Update the style if there are pending changes
    if (changesPending) {
      this.updateSplicer()
      this.updateWrapperStyle()
      this.updateTextStyle()
      this.updateBackground()
      this.updateTextContent()
      this.updateResizerGroup()
    }
  }

  /* == Support == */
  updateWrapperStyle () {
    const align = { left: 'flex-start', center: 'center', right: 'flex-end' }
    const valign = { top: 'flex-start', center: 'center', bottom: 'flex-end' }

    Object.assign(this.element.style, {
      width: `${this.width}px`,
      height: `${this.height}px`,
      transform: `translate(${this.x}px, ${this.y}px) rotate(${this.rotate}deg) skew(${this.skewX}deg, ${this.skewY}deg)`, // Transform is done right to left. Do not change order.
      justifyContent: align[this.align],
      alignItems: valign[this.valign],
      textAlign: this.align
    })
  }

  updateTextStyle () {
    Object.assign(this.textTarget.style, {
      width: this.constrainContent() ? '100%' : null,
      maxHeight: this.constrainContent() ? '100%' : null,
      fontFamily: this.font,
      fontSize: `${this.size}px`,
      lineHeight: `${this.lineHeight}px`,
      textTransform: this.letterCase || 'none',
      letterSpacing: `${this.letterSpacing}px`,
      color: `#${this.color}`,
      textShadow: this.textShadow,
      webkitTextStrokeColor: `#${this.textStrokeColor}`,
      webkitTextStrokeWidth: `${this.textStrokeWidth}px`
    })
  }

  /* == Update legacy fragments that contain defective code */
  updateLegacyFragment () {
    if (this.element.dataset.target) {
      this.element.removeAttribute('data-target')
      this.element.setAttribute('data-setout--editor-target', 'fragment')
    }

    this.textTarget.removeAttribute('data-original-line-height')
    this.textTarget.removeAttribute('data-original-font-size')
  }

  updateBackground () {
    if (this.element.dataset.background === 'true' && this.color.length >= 6) {
      const [red, green, blue] = this.color.replace('#', '').match(/.{2}/g).map(color => parseInt(color, 16))
      this.element.style.background = `rgba(${red}, ${green}, ${blue}, 0.2)`
    } else {
      this.element.style.background = ''
    }
  }

  updateTextContent () {
    this.textTarget.innerHTML = this.text
  }

  updateResizerGroup () {
    const resizerGroup = this.element.getAttribute('data-text-resizer-group')
    if (resizerGroup) {
      this.textTarget.setAttribute('data-resizer-group', resizerGroup)
    } else {
      this.textTarget.removeAttribute('data-resizer-group')
    }
  }

  updateSplicer () {
    const splicer = this.element.dataset.splicer
    if (splicer) {
      this.textTarget.setAttribute('id', splicer.replace(/_/g, '-'))
    } else {
      this.textTarget.removeAttribute('id')
    }
  }

  constrainContent () {
    return this.splicer === 'photo'
  }

  /* == Getters & Setters == */
  set background (value) {
    this.element.setAttribute('data-background', value)
    this.updateBackground()
  }

  get background () {
    return this.element.dataset.background
  }

  set x (value) {
    this.element.setAttribute('data-x', value.toFixed(0))
  }

  get x () {
    return Number(this.element.dataset.x)
  }

  set y (value) {
    this.element.setAttribute('data-y', value.toFixed(0))
  }

  get y () {
    return Number(this.element.dataset.y)
  }

  set skewX (value) {
    this.element.setAttribute('data-skew-x', value)
  }

  get skewX () {
    return Number(this.element.dataset.skewX)
  }

  set skewY (value) {
    this.element.setAttribute('data-skew-y', value)
  }

  get skewY () {
    return Number(this.element.dataset.skewY)
  }

  set rotate (value) {
    this.element.setAttribute('data-rotate', value)
  }

  get rotate () {
    return Number(this.element.dataset.rotate)
  }

  set width (value) {
    this.element.setAttribute('data-width', value.toFixed(0))
  }

  get width () {
    return Number(this.element.dataset.width)
  }

  set height (value) {
    this.element.setAttribute('data-height', value.toFixed(0))
  }

  get height () {
    return Number(this.element.dataset.height)
  }

  set align (value) {
    this.element.setAttribute('data-align', value)
  }

  get align () {
    return this.element.dataset.align
  }

  set valign (value) {
    this.element.setAttribute('data-valign', value)
  }

  get valign () {
    return this.element.dataset.valign
  }

  set letterCase (value) {
    this.element.setAttribute('data-letter-case', value)
  }

  get letterCase () {
    return this.element.dataset.letterCase
  }

  set letterSpacing (value) {
    this.element.setAttribute('data-letter-spacing', value)
  }

  get letterSpacing () {
    return this.element.dataset.letterSpacing
  }

  get textShadowX () {
    return this.element.dataset.textShadowX
  }

  get textShadowY () {
    return this.element.dataset.textShadowY
  }

  get textShadowR () {
    return this.element.dataset.textShadowR
  }

  get textShadowColor () {
    const color = this.element.dataset.textShadowColor
    if (color) return color.replace('#', '')
  }

  set textShadowColor (color) {
    this.element.setAttribute('data-text-shadow-color', color)
  }

  get textStrokeColor () {
    const color = this.element.dataset.textStrokeColor
    if (color) return color.replace('#', '')
  }

  set textStrokeColor (color) {
    this.element.setAttribute('data-text-stroke-color', color)
  }

  get textStrokeWidth () {
    return this.element.dataset.textStrokeWidth || '0'
  }

  set textStrokeWidth (width) {
    this.element.setAttribute('data-text-stroke-width', width)
  }

  get textShadow () {
    if (this.textShadowX && this.textShadowY && this.textShadowR && this.textShadowColor) {
      return `${this.textShadowX}px ${this.textShadowY}px ${this.textShadowR}px #${this.textShadowColor}`
    } else {
      return 'none'
    }
  }

  set font (value) {
    this.element.setAttribute('data-font', value)
  }

  get font () {
    return this.element.dataset.font
  }

  set size (value) {
    this.element.setAttribute('data-size', value)
  }

  get size () {
    return Number(this.element.dataset.size)
  }

  set splicer (value) {
    this.element.setAttribute('data-splicer', value)
    this.textTarget.setAttribute('id', value.replace(/_/g, '-'))
  }

  get splicer () {
    return this.element.dataset.splicer
  }

  set lineHeight (value) {
    this.element.setAttribute('data-line-height', value.toFixed(2).replace('.', ','))
  }

  get lineHeight () {
    const lineHeight = parseFloat(this.element.dataset.lineHeight)
    if (lineHeight && lineHeight <= 2) {
      return this.size * lineHeight
    } else {
      return lineHeight
    }
  }

  set color (value) {
    this.element.setAttribute('data-color', value)
    this.updateBackground()
  }

  get color () {
    return this.element.dataset.color
  }

  set text (value) {
    this.element.setAttribute('data-text', value)
  }

  get text () {
    return this.element.dataset.text
  }

  get scale () {
    return Number(this.element.dataset.scale || 1)
  }
}
