import { Controller } from '@hotwired/stimulus'

import loadImage from 'blueimp-load-image/js/load-image'
import Cropper from 'cropperjs'

export default class extends Controller {
  static targets = ['xDesktop', 'yDesktop', 'rotateDesktop', 'widthDesktop', 'heightDesktop', 'xMobile', 'yMobile', 'rotateMobile', 'widthMobile', 'heightMobile', 'fileBride', 'fileGroom', 'photo', 'resizeButton', 'chooseButton', 'restartButton', 'saveButton', 'removeButton', 'imageLoader', 'error', 'photoWrapper', 'subHeader', 'usage', 'brideGroup', 'groomGroup', 'photoWrapperBride', 'photoBride']

  connect () {
    this.data.set('photoGroom', this.photoTarget.src)
    this.data.set('photoBride', this.photoBrideTarget.src)
  }

  changeGroom (e) {
    const file = e.target.files[0]
    const usage = 'groom'
    if (file && (/image\/(jpg|jpeg|png|webp|gif)/.test(file.type))) {
      this.toggleElements('loading')
      loadImage(file, (img) => {
        this.photoTarget.src = img.src
        this.initEditor(usage)
      }, { noRevoke: true })
    } else {
      this.toggleError()
      e.target.value = ''
    }
  }

  changeBride (e) {
    const file = e.target.files[0]
    const usage = 'bride'
    if (file && (/image\/(jpg|jpeg|png|webp|gif)/.test(file.type))) {
      this.toggleElements('loading')
      loadImage(file, (img) => {
        this.photoTarget.src = img.src
        this.initEditor(usage)
      }, { noRevoke: true })
    } else {
      this.toggleError()
      e.target.value = ''
    }
  }

  newBride () {
    this.toggleElements('bride')
  }

  newGroom () {
    this.toggleElements('groom')
  }

  restart () {
    this.cropper.destroy()
    this.cropper = null
    this.fileBrideTarget.value = ''
    this.fileGroomTarget.value = ''
    this.photoTarget.src = this.data.get('photo')
    this.toggleElements('restart')
  }

  editGroom () {
    this.toggleElements('loading')
    this.resize('groom', this.data.get('photoGroom'))
  }

  editBride (e) {
    this.toggleElements('loading')
    this.resize('bride', this.data.get('photoBride'))
  }

  resize (usage, target) {
    this.photoTarget.src = target
    this.initEditor(usage)
  }

  submit () {

  }

  initEditor (usage) {
    this.toggleElement(this.photoWrapperTarget, true)
    this.cropper = new Cropper(this.photoTarget, {
      aspectRatio: 1,
      viewMode: 1,
      autoCropArea: 0.9,
      cropBoxResizable: false,
      cropBoxMovable: false,
      dragMode: 'move',
      crop: (e) => {
        if (!this.exifRotate) this.exifRotate = e.detail.rotate

        this.xDesktopTarget.value = e.detail.x
        this.yDesktopTarget.value = e.detail.y
        this.heightDesktopTarget.value = e.detail.height
        this.widthDesktopTarget.value = e.detail.width
        this.xMobileTarget.value = e.detail.x
        this.yMobileTarget.value = e.detail.y
        this.heightMobileTarget.value = e.detail.height
        this.widthMobileTarget.value = e.detail.width
        this.usageTarget.value = usage

        // iOS is a fucking moron and all photos are rotated by default. Compensate user rotation by it
        this.rotateDesktopTarget.value = e.detail.rotate - this.exifRotate
        this.rotateMobileTarget.value = e.detail.rotate - this.exifRotate
      },
      ready: () => {
        this.toggleElements('editing')
      }
    })
  }

  toggleElements (status) {
    switch (status) {
      case 'loading':
        this.element.classList.add('photo-editor--editing')
        this.toggleElement(this.photoTarget, false, 'invisible')
        this.toggleElement(this.chooseButtonTarget, false)
        if (this.hasResizeButtonTarget) this.toggleElement(this.resizeButtonTarget, false)
        if (this.hasRemoveButtonTarget) this.toggleElement(this.removeButtonTarget, false)
        break
      case 'bride':
        this.toggleElement(this.subHeaderTarget, false)
        this.toggleElement(this.brideGroupTarget, true)
        this.toggleElement(this.photoTarget, false)
        this.toggleElement(this.saveButtonTarget, false)
        this.toggleElement(this.restartButtonTarget, false)
        this.toggleElement(this.chooseButtonTarget, false)
        this.toggleElement(this.removeButtonTarget, false)
        break
      case 'groom':
        this.toggleElement(this.groomGroupTarget, true)
        this.toggleElement(this.subHeaderTarget, false)
        this.toggleElement(this.photoTarget, true)
        this.toggleElement(this.saveButtonTarget, false)
        this.toggleElement(this.restartButtonTarget, false)
        this.toggleElement(this.chooseButtonTarget, false)
        this.toggleElement(this.removeButtonTarget, false)
        break
      case 'editing':
        this.toggleElement(this.groomGroupTarget, false)
        this.toggleElement(this.brideGroupTarget, false)
        this.toggleElement(this.subHeaderTarget, false)
        this.toggleElement(this.photoTarget, true, 'invisible')
        this.toggleElement(this.saveButtonTarget, true)
        break
      default:
        this.element.classList.remove('photo-editor--editing')
        this.toggleElement(this.restartButtonTarget, false)
        this.toggleElement(this.saveButtonTarget, false)
        this.toggleElement(this.chooseButtonTarget, true)
        if (this.hasResizeButtonTarget) this.toggleElement(this.resizeButtonTarget, this.hasPhoto())
        if (this.hasRemoveButtonTarget) this.toggleElement(this.removeButtonTarget, this.hasPhoto())
    }
  }

  toggleElement (element, force, klass = 'hidden') {
    element.classList.toggle(klass, !force)
  }

  toggleError () {
    this.errorTarget.classList.toggle('hidden')
  }

  hasPhoto () {
    return this.data.get('hasPhoto') === 'true'
  }
}
