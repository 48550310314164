import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['controlCheckbox', 'overlay']

  close () {
    this.overlay(false)
    this.noScroll(false)
    this.controlCheckbox(false)
  }

  toggle () {
    const status = this.status()
    this.overlay(status)
    this.noScroll(status)
  }

  overlay (status) {

  }

  noScroll (status) {
    document.body.classList.toggle('noscroll', status)
  }

  controlCheckbox (status) {
    this.controlCheckboxTarget.checked = status
  }

  status () {
    if (this.hasControlCheckboxTarget) {
      return !this.controlCheckboxTarget.checked // event triggered before checkbox is flipped
    }

    return null
  }
}
