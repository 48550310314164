import { Controller } from '@hotwired/stimulus'
import DirectUpload from '../../lib/direct_upload'

/*
 * This is FestaLab's ajax controller. It is applied to the body of the
 * HTML document and therefore its functions are available in all pages.
 * It's a replacement for both Rails UJS remote fields and rails form_with
 *
 * Major features are:
 *
 * 1 - Support for GET, POST, PATCH and DELETE methods
 * 2 - Support for triggering on any javascript event, even custom ones
 * 3 - Choosing the target using data-target attribute
 * 4 - Choosing how response will be inserted using data-swap attribute
 * 5 - Choosing which inputs will be send along using data-params attribute
 * 6 - Alternate activations: delay and pooling
 * 7 - Direct uploads to cloud storage
 *
 * TODO:
 * 1 - Lock element from further requests
 */

export default class extends Controller {
  // ============================================================================
  // LIFE CYCLE
  // ============================================================================
  /*
   * When connecting, prepare elements that rely on 'delay' and 'pooling' activations
   */
  connect () {
    this.customActivations = {}
    this.bindCustomActivations()
  }

  // ============================================================================
  // ACTIONS
  // ============================================================================

  /*
   * Issues a GET request to the server
   */
  get (event) {
    this.handleAjaxRequest(event, 'GET')
  }

  /*
   * Issues a POST request to the server
   */
  post (event) {
    this.handleAjaxRequest(event, 'POST')
  }

  /*
   * Issues a PATCH request to the server
   */
  patch (event) {
    this.handleAjaxRequest(event, 'PATCH')
  }

  /*
   * Issues a DELETE request to the server
   */
  delete (event) {
    this.handleAjaxRequest(event, 'DELETE')
  }

  /*
   * Downloads the requested url
   */
  download (event) {
    const link = document.createElement('a')
    const { url, filename } = event.currentTarget.dataset

    link.href = url
    link.download = filename
    document.body.append(link)
    link.click()
    link.remove()
    window.URL.revokeObjectURL(url)
  }

  // ============================================================================
  // INTERNAL: AJAX ENGINE
  // ============================================================================
  /*
   * Ajax calls can be triggered by manual events, debounced events, etc.
   * This method detects which type of trigger is happening and handles
   * it appropriately.
   *
   * For example, a manual event (click->ajax#get) will be dispatched
   * directly to issueAjaxRequest. A debounced event (data-activation="delay")
   * will be dispatched to customActivations.
   *
   * For standard calls, it will also check for the presence of direct upload fields
   * and upload them before performing the ajax call
   *
   * Finally, this method prevents the browser from submitting forms so that they
   * can be posted via ajax.
   */
  async handleAjaxRequest (event, verb) {
    if (event.type === 'submit') event.preventDefault()
    const target = event.currentTarget

    const { activationId } = target.dataset
    if (activationId) {
      this.customActivations[activationId]()
    } else {
      await this.handleDirectUpload(target)
      this.issueAjaxRequest(target, verb)
    }
  }

  /*
   * Handles redirection after an ajax request. If HTTP verb
   * used was a GET, advances the page, leaving it in the cache.
   * Otherwise, replace the current page
   */
  handleRedirect (element, redirect, verb) {
    const url = redirect === 'self' ? window.location : redirect
    const action = verb === 'GET' ? 'advance' : 'replace'

    // Only redirect if element does not have a no-redirect instruction
    if (redirect !== 'false') {
      Turbo.cache.clear()
      Turbo.visit(url, { action: action })
    } else {
      this.enableElement(element)
    }
  }

  /*
   * If there are file inputs in the form, uploads them to the cloud storage.
   * After the upload is done, add a hidden field with the blob signed id so
   * that it is picked up during the form submission and sent to the controller
   *
   * For now it does not have support for multiple files
   */
  handleDirectUpload (target) {
    const input = target.querySelector('input[type=file]')
    if (!input) return

    return new DirectUpload(input).upload()
  }

  /*
   * Performs the required ajax call. Detailed explanations will
   * be on each method called, but the steps are:
   *
   * 1 - Check if the triggering element is valid, and stop if not.
   * 2 - Prepare the headers so that the server can handle the request
   * 3 - If the verb is anything but GET, ready the payload of inputs
   * 4 - If the verb is GET, add any inputs in data-params to the url
   * 5 - Disable the triggering element to prevent further requests
   * 6 - Send the request
   * 7 - When the response is received:
   * 6.1 - If the response was "no content" or has a redirect header, redirect
   * 6.2 - If the response was "ok", enable the trigger and insert the HTML
 */
  issueAjaxRequest (element, verb) {
    verb = verb.toUpperCase()

    // Do not issue an ajax request for an element that is marked as invalid
    this.fire(element, 'ajax:before')
    if (element.dataset.valid === 'false') return

    // Prepare everything that will be needed for the request
    const headers = this.prepareHeaders(verb)
    const payload = this.preparePayload(element, verb)
    const path = this.preparePath(element, verb, payload)
    const responseType = this.prepareResponseType(element)

    // Create the request and ensure its treated as html
    const xhr = new XMLHttpRequest()
    xhr.open(verb, path, true)
    xhr.overrideMimeType('text/html')
    xhr.responseType = responseType

    // Add the headers to the request so that the server knows how to handle it
    Object.keys(headers).forEach(header => {
      xhr.setRequestHeader(header, headers[header])
    })

    // Setup the callback that will handle the response
    xhr.onload = () => {
      const status = xhr.status
      const response = (responseType === 'document' ? xhr.response : xhr.responseText)
      const redirect = element.dataset.redirect || xhr.getResponseHeader('X-Xhr-Redirect') // DO NOT CHANGE!

      if (status === 200 && redirect) {
        this.handleRedirect(element, redirect, verb)
      } else if (status === 204) {
        this.enableElement(element)
        this.delayedFire(element, 'ajax:success')
      } else if (status >= 200 && status < 300) {
        this.enableElement(element)
        this.insert(element, response)
        this.delayedFire(element, 'ajax:success')
      } else if (status === 406) {
        this.enableElement(element)
        this.insert(element, response)
        this.delayedFire(element, 'ajax:invalid')
      } else {
        this.enableElement(element)
        this.delayedFire(element, 'ajax:error')
      }

      this.delayedFire(element, 'ajax:done')
      this.delayedFire(document.body, 'ajax:done')
    }

    // Do not disable the target element before this, or the 'prepare' methods will break
    this.disableElement(element)

    // Send the ajax request. If the verb is not GET, send the payload
    // as the request body (GET request will have the payload url encoded in the path).
    xhr.send(verb === 'GET' ? null : this.encodePayload(payload))
  }

  // ============================================================================
  // INTERNAL: PAYLOAD HANDLING
  // ============================================================================

  /*
   * Locates all inputs/vars that should be part of this request and returns then
   * as a hash of name/value pairs to be used either in the URL (GET) or body.
   */
  preparePayload (element, verb) {
    const values = {}
    const processed = []

    // For a non-GET include the closest form. For GET requests include the params
    if (verb !== 'GET') {
      this.addElementToPayload(processed, values, element.closest('form'))
    } else {
      this.addParamsToPayload(processed, values, element)
    }

    // Include the trigger element itself (also handles GET Forms)
    this.addElementToPayload(processed, values, element)

    // Include vars in the element
    this.addVarsToPayload(values, element)

    // Filters values according to data-params
    return this.filterPayload(values, element)
  }

  /*
   * If the triggering element has the data-params attribute,
   * add all inputs contained within in the payload. They will
   * later be filtered so that only the inputs of the correct
   * params are included
   */
  addParamsToPayload (processed, values, element) {
    const config = this.extractConfig(element, 'params')
    if (config.params && config.params !== 'none') {
      config.element.querySelectorAll('input').forEach(element => {
        this.addElementToPayload(processed, values, element)
      })

      config.element.querySelectorAll('select').forEach(element => {
        this.addElementToPayload(processed, values, element)
      })
    }
  }

  /*
   * Add an element to the payload, as long as it has not
   * been added before and it is not an invalid element.
   *
   * If multiple elements have the same name, the value will
   * be turned into an array.
   *
   * If the element is a form, all its inputs all be added.
   */
  addElementToPayload (processed, values, element) {
    if (element == null || this.alreadyAddedToPayload(processed, element)) {
      return
    } else {
      processed.push(element)
    }

    // Check if the element is a valid input before processing it
    if (this.shouldAddToPayload(element)) {
      const name = element.name
      const value = element.value

      if (value != null) {
        // Store the value and handle arrays gracefully
        const current = values[name]
        if (current) {
          if (Array.isArray(current)) {
            current.push(value)
          } else {
            values[name] = [current, value]
          }
        } else {
          values[name] = value
        }
      }
    }

    // If this element is a form, loop on its elements
    if (element.matches('form')) {
      const inputs = element.elements
      for (let i = 0; i < inputs.length; i++) {
        this.addElementToPayload(processed, values, inputs[i])
      }
    }
  }

  /*
   * If the element has the data-vars attribute, add those
   * to the payload
   */
  addVarsToPayload (values, element) {
    const { vars } = this.extractConfig(element, 'vars')
    if (!vars) return

    vars.split(',').forEach(kvp => {
      const pair = kvp.trim().split('=')

      // Store the value and handle arrays gracefully
      if (pair[0].indexOf('[]') > 0) {
        if (Array.isArray(values[pair[0]])) {
          values[pair[0]].push(pair[1])
        } else {
          values[pair[0]] = [pair[1]]
        }
      } else {
        values[pair[0]] = pair[1]
      }
    })
  }

  /*
   * Make sure that invalid elements are not added to the payload, as per HTML spec.
   * 1 - Elements without a name or disabled
   * 3 - Submitter types (jquery/serialize.js)
   * 4 - Unchecked selectors
   * 5 - File inputs, which are handled through direct upload
   */
  shouldAddToPayload (element) {
    if (element.name === '' || element.name == null || element.disabled) {
      return false
    }

    if (element.type === 'button' || element.type === 'submit' || element.tagName === 'image' || element.tagName === 'reset' || element.tagName === 'file') {
      return false
    }

    if (element.type === 'checkbox' || element.type === 'radio') {
      return element.checked
    }

    if (element.type === 'file') {
      return false
    }

    return true
  }

  /*
   * Make sure that no elements are added twice to the payload
   */
  alreadyAddedToPayload (processed, element) {
    return !!processed.find(elt => element.isSameNode(elt))
  }

  /*
   * If the element has a data-params attribute,
   * make sure that only the attributes listed there
   * are present in the payload
   */
  filterPayload (values, element) {
    const { params } = this.extractConfig(element, 'params')
    if (!params) {
      return values
    } else if (params === 'none') {
      return {}
    } else if (params === 'all') {
      return values
    } else {
      const newValues = {}
      params.split(',').forEach((param) => {
        param = param.trim()
        newValues[param] = values[param]
      })
      return newValues
    }
  }

  /*
   * Turns a hash into an encoded string that can be
   * used in the url or body of the ajax request
   */
  encodePayload (values) {
    let url = ''

    for (const key in values) {
      if (Object.prototype.hasOwnProperty.call(values, key)) {
        const value = values[key]
        if (Array.isArray(value)) {
          value.forEach(v => { url = this.appendParamToUrl(url, key, v) })
        } else {
          url = this.appendParamToUrl(url, key, value)
        }
      }
    }

    return url
  }

  // ============================================================================
  // INTERNAL: PATH
  // ============================================================================

  /*
   * Extracts the path from the data-url of the triggering element, the action of
   * the triggering element (forms) or the current document location.
   *
   * If the verb is GET and there is a payload that must be sent, then it is url
   * encoded into the path, with the anchor at the end
   */
  preparePath (element, verb, payload) {
    let path = this.extractConfig(element, 'url').value || element.action || document.location.href

    // If this is a GET request and we DO have a payload, we must add it to the Path
    if (verb === 'GET') {
      const splitPath = path.split('#')
      const anchor = splitPath[1]
      path = splitPath[0]

      if (Object.keys(payload).length !== 0) {
        if (path.indexOf('?') < 0) {
          path += '?'
        } else {
          path += '&'
        }

        path += this.encodePayload(payload)
        if (anchor) {
          path += '#' + anchor
        }
      }

      return path
    } else {
      return path
    }
  }

  // ============================================================================
  // INTERNAL: HEADERS & RESPONSE
  // ============================================================================

  /*
   * Returns a hash of headers that are encessary for the server to properly handle
   * the request
   *
   * X-Requested-With: Will make the server understand this was an ajax request.
   *                   It will also force redirects to be returned as code 200 with
   *                   the X-Xhr-Redirect header set
   * Accept: Forces the server to return an HTML response
   * Content-Type: Indicates the format that the payload uses
   * X-CSRF-Token: Plays nice with server side forgery protection
   */
  prepareHeaders (verb) {
    const headers = {
      'X-Requested-With': 'XMLHttpRequest',
      'Accept': 'text/html'
    }

    if (verb !== 'GET') {
      headers['Content-Type'] = 'application/x-www-form-urlencoded'

      const token = document.querySelector('meta[name="csrf-token"]')
      if (token) headers['X-CSRF-Token'] = token.attributes.content.value
    }

    return headers
  }

  /*
   * If the user wants to use the page as the target, the server should answer with a
   * full html document. In this case, the the responseType to 'document' so that
   * XmlHttpRequest will parse it correctly
   */
  prepareResponseType (element) {
    return this.extractConfig(element, 'target').value === 'page' ? 'document' : ''
  }

  // ============================================================================
  // INTERNAL: DOM MANIPULATION
  // ============================================================================

  /*
   * Inserts the content of the incoming response into the document according to
   * the various data attributes defined in the trigger.
   */
  insert (element, response) {
    // If response is empty, don't do anything
    if (response === '') return

    // Check that the triggering element is still in the page
    if (!document.body.contains(element)) return

    const html = this.parseResponse(element, response)
    const swap = this.chooseSwap(element)
    const target = this.chooseTarget(element)
    const { extract } = this.extractConfig(element, 'extract')

    if (extract) {
      this.insertExtract(target, extract, html)
    } else if (target === 'auto') {
      this.insertAuto(swap, html)
    } else if (target === 'multiple') {
      this.insertMultiple(swap, html)
    } else if (target === 'content') {
      this.insertContent(html)
    } else {
      this.insertSwap(target, swap, html)
    }
  }

  insertExtract (target, extract, html) {
    extract.split(',').forEach(name => {
      const selector = `[name='${name.trim()}']`
      const destination = target.querySelector(selector)
      const source = html.querySelector(selector)

      destination.value = source.value
      this.fire(destination, 'input')
    })

    this.bindCustomActivations()
    this.delayedFire(target, 'ajax:load')
  }

  insertAuto (swap, html) {
    const tmp = document.createElement('html')
    tmp.innerHTML = html.trim()
    const body = tmp.querySelector('body')

    const target = document.querySelector(`#${body.firstElementChild.id}`)
    target[swap] = html
  }

  insertContent (html) {
    const tmp = document.createElement('html')
    tmp.innerHTML = html.trim()
    const body = tmp.querySelector('body')

    const target = document.querySelector(`#${body.firstElementChild.id}`)
    target.innerHTML = body.firstElementChild.innerHTML
  }

  insertMultiple (swap, html) {
    const element = document.createElement('html')
    element.innerHTML = html.trim()
    const sources = element.querySelector('body').children

    sources.forEach(source => {
      const target = document.querySelector(`#${source.id}`)

      if (document.body.contains(target)) {
        target[swap] = source[swap]

        this.bindCustomActivations()
        this.delayedFire(target, 'ajax:load')
      }
    })
  }

  insertSwap (target, swap, html) {
    if (swap === 'innerHTML' || swap === 'outerHTML') {
      target[swap] = html
    } else {
      target.insertAdjacentHTML(swap, html)
    }

    this.bindCustomActivations()
    this.delayedFire(target, 'ajax:load')
  }

  /*
   * Disables an element by adding the `disabled` class and attribute to it
   */
  disableElement (element) {
    this.toggleElement(element, false)
  }

  /*
   * Enables an element by removing the `disabled` class and attribute from it
   */
  enableElement (element) {
    this.toggleElement(element, true)
  }

  /*
   * Enables/Disables an element by toggling the `disabled` class and attribute on it
   */
  toggleElement (element, force) {
    if (!element || element.dataset.disable === 'false') {
      return
    }

    // Android chrome as usual is buggy and doesn' blur when an input is disabled
    if (element.tagName === 'INPUT' && !force) {
      element.blur()

      /*
       * We sometimes use <button form="id"> to submit forms from outside. This ensures said
       * button is also toggled along with the form
       */
    } else if (element.tagName === 'FORM') {
      const submit = document.querySelector(`[form='${element.id}']`)
      this.toggleElement(submit, force)
    }

    element.disabled = !force
    element.classList.toggle('disabled', !force)
  }

  /*
   * Given an element, extract from it or the closest ancestor the attribute we are looking
   * for. Example: attribute = 'swap', will first look in the element for data-swap, and if
   * not found, will search through all ancestors of the element for data-swap.
   */
  extractConfig (element, attribute) {
    const key = attribute.replace(/-([a-z])/g, g => g[1].toUpperCase())
    const selector = attribute.replace(/([a-zA-Z])(?=[A-Z])/g, '$1-').toLowerCase()

    const source = element.dataset[key] ? element : element.closest(`[data-${selector}]`)
    if (!source) return { element }

    const config = {}
    config.element = source
    config.value = source.dataset[key]
    config[key] = source.dataset[key]

    return config
  }

  /*
   * Analyses the response and figures out which part of it contains the HTML to
   * be inserted into the document
   */
  parseResponse (element, response) {
    // Response is an HTMLDocument (target=page), return the body
    if (response instanceof HTMLDocument) {
      return response.body.innerHTML
    }

    // A specific fragment of the response was requested, extract it and return
    const { select } = this.extractConfig(element, 'select')
    if (select) {
      const content = document.createElement('html')
      content.innerHTML = response.trim()
      return content.querySelector(select).outerHTML
    }

    // Specific inputs were requested, turn the response into an HTML element
    // so we can use it later
    const { extract } = this.extractConfig(element, 'extract')
    if (extract) {
      const content = document.createElement('html')
      content.innerHTML = response.trim()
      return content
    }

    // No further processing required, return as is
    return response
  }

  bindCustomActivations () {
    document.querySelectorAll('[data-action][data-activation*="delay"]:not([data-activation-id])').forEach(this.delay.bind(this))
    document.querySelectorAll('[data-action][data-activation*="pool"]:not([data-activation-id])').forEach(this.pool.bind(this))
  }

  // ============================================================================
  // INTERNAL: UTILITY
  // ============================================================================

  /*
   * Returns a function, that, as long as it continues to be invoked, will not
   * be triggered. The function will be called after it stops being called for
   * N milliseconds. If `immediate` is passed, trigger the function on the
   * leading edge, instead of the trailing.
   */
  debounce (func, wait, immediate) {
    let timeout

    return function () {
      const context = this
      const args = arguments
      const later = function () {
        timeout = null
        if (!immediate) func.apply(context, args)
      }
      const callNow = immediate && !timeout
      clearTimeout(timeout)
      timeout = setTimeout(later, wait)
      if (callNow) func.apply(context, args)
    }
  }

  /*
   * Dispatches an event on the object, but delays this to the next micro task
   * which gives other code in the task the chance to rebind anything they need
   */
  delayedFire (obj, name) {
    Promise.resolve().then(() => {
      this.fire(obj, name)
    })
  }

  /*
   * Dispatches an event on the object
   */
  fire (obj, name) {
    // Check that the target element is still in the page
    if (!document.body.contains(obj)) return

    const event = new CustomEvent(name, {
      bubbles: true,
      cancelable: true,
      detail: null
    })
    obj.dispatchEvent(event)
    return !event.defaultPrevented
  }

  /*
   * Generate a random string in a fast way
   */
  randomId () {
    return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)
  }

  /*
   * Encodes the name and value of a param and appends to the url, ensuring
   * it is preceeded by an ampersand
   */
  appendParamToUrl (url, name, value) {
    if (url !== '') url += '&'

    url += encodeURIComponent(name) + '=' + encodeURIComponent(value)

    return url
  }

  // ============================================================================
  // CONFIGURATION: DATA-ACTIVATION
  // ============================================================================
  delay (element) {
    const delay = Number(element.dataset.activation.split(' ')[1])
    const action = element.dataset.action.match(/ajax#([a-z]*)/)[1]
    const id = this.randomId()

    element.setAttribute('data-activation-id', id)
    this.customActivations[id] = this.debounce(() => {
      this.issueAjaxRequest(element, action)
    }, delay)

    this.delayedFire(element, 'ajax:load')
  }

  pool (element) {
    const delay = Number(element.dataset.activation.split(' ')[1])
    const action = element.dataset.action.split('#')[1]
    const id = this.randomId()

    element.setAttribute('data-activation-id', id)
    this.customActivations[id] = setInterval(() => {
      if (document.body.contains(element)) {
        this.issueAjaxRequest(element, action)
      } else {
        clearInterval(this.customActivations[id])
      }
    }, delay)
  }

  // ============================================================================
  // CONFIGURATION: DATA-SWAP
  // ============================================================================
  /*
   * Determines the swap strategy that will be used to insert the response into
   * the document. An 'auto' target defaults to 'outerHTML', otherwise 'innerHTML'
   * or the value of the data-swap attribute will be used
   */
  chooseSwap (element) {
    const { target } = this.extractConfig(element, 'target')
    const { swap } = this.extractConfig(element, 'swap')

    if (target === 'page' || target === 'content') {
      return 'innerHTML'
    } else if (target === 'auto' || target === 'multiple') {
      return swap || 'outerHTML'
    } else {
      return swap || 'innerHTML'
    }
  }

  // ============================================================================
  // CONFIGURATION: DATA-TARGET
  // ============================================================================
  /*
   * Determines the target that will be used as a reference point to insert the
   * response into the document. A 'auto' target will automatically search for
   * an element of the same id of the response, otherwise the trigger of the
   * ajax call or the element specified in the data-target attribute will be
   * used.
   */
  chooseTarget (element) {
    const config = this.extractConfig(element, 'target')

    if (config.target === 'auto' || config.target === 'multiple' || config.target === 'content') {
      return config.value
    } else if (config.target.indexOf('closest') !== -1) {
      return config.element.closest(config.target.split(' ')[1])
    } else if (config.target === 'parent') {
      return config.element.parentElement
    } else if (config.target === 'this') {
      return config.element
    } else if (config.target === 'page') {
      return document.body
    } else {
      return document.querySelector(config.target)
    }
  }
}
