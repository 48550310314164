import { Controller } from '@hotwired/stimulus'
import Rails from '../../rails/rails-ujs'

export default class extends Controller {
  static targets = ['days', 'hours', 'minutes', 'message']

  initialize () {
    this.updateCountdown()
  }

  connect () {
    this.internal = setInterval(() => this.updateCountdown(), 60000)
    this.closeModal = this.data.get('close-modal')
  }

  disconnect () {
    clearInterval(this.interval)
  }

  updateCountdown () {
    let deltaTime = this.scheduledDate.getTime() - new Date().getTime()

    if (this.isExpired()) {
      if (this.hasMessageTarget) {
        this.messageTarget.classList.remove('hidden')
      }
      if (this.closeModal) {
        Rails.fire(this.element.closest('.modal'), 'close')
      }
      deltaTime = new Date().getTime() - this.scheduledDate.getTime()
    }

    const minutes = 60 * 1000
    const hours = 60 * minutes
    const days = 24 * hours

    this.days = Math.floor(deltaTime / days)
    this.hours = Math.floor((deltaTime % days) / hours)
    this.minutes = Math.ceil((deltaTime % hours) / minutes)
  }

  isExpired () {
    return this.scheduledDate < new Date().getTime()
  }

  get scheduledDate () {
    const [year, month, day] = this.data.get('scheduled-on').split('-')
    const [hours, minutes] = this.data.get('starts-at').split(':')

    return new Date(year, (month - 1), day, hours, minutes)
  }

  set days (value) {
    this.daysTarget.textContent = value
  }

  set hours (value) {
    if (value < 10) value = [0, value].join('')
    this.hoursTarget.textContent = value
  }

  set minutes (value) {
    if (value < 10) value = [0, value].join('')
    this.minutesTarget.textContent = value
  }
}
