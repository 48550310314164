import { Controller } from '@hotwired/stimulus'
import Rails from '../../rails/rails-ujs'

export default class extends Controller {
  // Actions
  open () {
    this.fetch((response, status, xhr) => {
      if (!document.querySelector('.modal')) {
        document.body.insertAdjacentHTML('beforeend', xhr.response)
      }
    })
  }

  replace () {
    this.fetch((response, status, xhr) => {
      if (document.querySelector('.modal')) {
        document.querySelector('.modal').outerHTML = xhr.response
      }
    })
  }

  // Support
  fetch (callback) {
    Rails.ajax({
      url: this.data.get('url'),
      type: 'GET',
      success: callback,
      error: (response, status, xhr) => {
        // Do nothing
      }
    })
  }
}
