import { Controller } from '@hotwired/stimulus'
import ClipboardJS from 'clipboard'

export default class extends Controller {
  connect () {
    this.clipboard = new ClipboardJS(this.element)
    this.clipboard.on('success', this.notify)
  }

  disconnect () {
    this.clipboard.destroy()
  }

  notify (e) {
    const button = e.trigger
    const originalText = button.innerHTML

    button.innerHTML = 'Copiado!'
    setTimeout(function () {
      button.innerHTML = originalText
    }, 3000)
  }
}
