import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['items', 'subitems', 'bar', 'overlay']

  connect () {
    this.observer = new IntersectionObserver(this.sticky.bind(this))
    this.observer.observe(document.querySelector('#cover'))
  }

  disconnect () {
    this.observer.unobserve(document.querySelector('#cover'))
  }

  initialize () {
    this.createOverlay()
  }

  open () {
    this.toggle(this.overlayTarget, true)
    this.toggle(this.itemsTarget, true)
  }

  close () {
    this.toggle(this.overlayTarget, false)
    this.toggle(this.itemsTarget, false)
    this.toggle(this.subitemsTarget, false)
  }

  next (e) {
    this.toggle(this.itemsTarget, false)
    this.toggle(this.subitemsTarget, true)

    e.preventDefault()
  }

  prev () {
    this.toggle(this.itemsTarget, true)
    this.toggle(this.subitemsTarget, false)
  }

  sticky (entries, observer) {
    entries.forEach((entry) => {
      this.barTarget.classList.toggle('section--sticky', !entry.isIntersecting)
    })
  }

  createOverlay () {
    const overlay = document.createElement('div')
    overlay.classList.add('overlay', 'hidden')
    overlay.setAttribute('style', 'display:block; background-color: rgba(255,255,255,0.8); z-index:1;')
    overlay.setAttribute('data-action', 'click->navigation#close')
    overlay.setAttribute('data-target', 'navigation.overlay')
    document.getElementById('navigation').prepend(overlay)
  }

  toggle (element, show) {
    element.classList.toggle('hidden', !show)
  }
}
