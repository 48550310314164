import { Controller } from '@hotwired/stimulus'
import Formatter from '../../../lib/formatter'

export default class extends Controller {
  static targets = ['total', 'quantity']

  connect () {
    this.calculateTotal()
  }

  calculateTotal () {
    this.total = this.price * this.quantity
  }

  // Getters & Setters

  set total (value) {
    this.totalTarget.dataset.total = value
    this.totalTarget.textContent = Formatter.currency(value)
  }

  get price () {
    return Number(this.quantityTarget.dataset.cartItemPrice)
  }

  get quantity () {
    return this.quantityTarget.value
  }
}
