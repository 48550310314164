import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect () {
    this.element.setAttribute('data-action', `confirmation#request ${this.element.dataset.action}`)
  }

  request (e) {
    if (!this.confirmed) this.open(e)
  }

  cancel (e) {
    this.close(e)
  }

  accept (e) {
    this.close(e)

    this.confirmed = true
    this.element.click()
  }

  open (e) {
    this.element.insertAdjacentElement('beforeend', this.dialog())
    e.preventDefault()
    e.stopImmediatePropagation()
  }

  close (e) {
    this.element.querySelector('.confirmation').remove()
    e.preventDefault()
    e.stopImmediatePropagation()
  }

  dialog () {
    const div = document.createElement('div')
    div.className = 'confirmation'

    const overlay = document.createElement('div')
    overlay.className = 'confirmation__overlay'
    overlay.setAttribute('data-action', 'click->confirmation#cancel')
    div.appendChild(overlay)

    const content = document.createElement('div')
    content.className = 'confirmation__content'
    div.appendChild(content)

    const message = document.createElement('p')
    message.className = 'confirmation__message'
    message.textContent = this.message
    content.appendChild(message)

    const actions = document.createElement('div')
    actions.className = 'confirmation__actions'
    content.appendChild(actions)

    const cancel = document.createElement('button')
    cancel.className = 'confirmation__cancel'
    cancel.type = 'button'
    cancel.title = 'Cancelar'
    cancel.textContent = this.cancelText
    cancel.setAttribute('data-action', 'confirmation#cancel')
    actions.appendChild(cancel)

    const confirm = document.createElement('button')
    confirm.className = 'confirmation__accept'
    confirm.type = 'button'
    confirm.title = 'Ok'
    confirm.textContent = this.confirmText
    confirm.setAttribute('data-action', 'confirmation#accept')
    actions.appendChild(confirm)

    return div
  }

  set confirmed (value) {
    this.data.set('confirmed', value)
  }

  get confirmed () {
    return this.data.get('confirmed') === 'true'
  }

  get message () {
    return this.data.get('message')
  }

  get cancelText () {
    return this.data.get('cancelText') || 'Voltar'
  }

  get confirmText () {
    return this.data.get('confirmText') || 'Sim, excluir'
  }
}
