import ParentController from './parent_controller'

export default class extends ParentController {
  static targets = ['journey', 'counter', 'bar', 'previous']

  connect () {
    this.updateProgress()
  }

  next () {
    this.updateProgress()
  }

  previous () {
    this.updateProgress()
  }

  goTo () {
    this.updateProgress()
  }

  /* == Logic == */
  updateProgress () {
    // The navigation controller does some actions through history.previous(), which sends the event to the end of the event loop
    // This short-delay timeout will make updating the progress go after it.
    setTimeout(() => {
      this.calculateProgress()

      this.updatePreviousButton()
      this.updateCounter()
      this.updateProgressBar()
    }, 10)
  }

  calculateProgress () {
    this.progress = 0
    const tracks = this.tracks

    for (let i = 0; i < tracks.length; i++) {
      const data = tracks[i].dataset

      if (data.progress !== 'false' && data.tracked !== 'false') this.progress++
      if (data.active) break // Sue me. Can't figure out how to make this shitty method look decent...
    }
  }

  updatePreviousButton () {
    if (!this.hasPreviousTarget) return

    this.previousTarget.classList.toggle('invisible', this.progress === 1 && this.previousTarget.dataset.hideBackAtStart === 'true')
  }

  updateCounter () {
    if (!this.hasCounterTarget) return

    if (!this.endTrack || this.progress === 0) {
      this.counterTarget.classList.add('invisible')
    } else {
      this.counterTarget.textContent = `${this.progress}/${this.endTrack}`
      this.counterTarget.classList.remove('invisible')
    }
  }

  updateProgressBar () {
    if (!this.hasBarTarget) return

    const progress = Number(this.progress) / Number(this.endTrack) * 100
    this.barTarget.style.width = `${progress}%`
  }

  /* == Getters and Setters == */
  set progress (value) {
    this.data.set('index', value)
  }

  get progress () {
    return Number(this.data.get('index'))
  }

  get endTrack () {
    return this.journeyTarget.dataset.endTrack
  }
}
