import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['progress', 'scroller', 'content', 'item', 'previous', 'next', 'dots', 'dot']

  // ==== Life Cycle ==== //
  initialize () {
    this.progressCallback = this.progress.bind(this)
    this.ticking = false
    if (this.indexable) this.paginate()
  }

  connect () {
    this.contentTarget.addEventListener('scroll', this.progressCallback)
    this.togglePrevious()
    this.toggleNext()
  }

  disconnect () {
    this.contentTarget.removeEventListener('scroll', this.progressCallback)
  }

  // ==== Actions ==== //
  previous () {
    this.contentTarget.scrollLeft -= this.contentTarget.offsetWidth / 0.8
    this.togglePrevious()
    this.toggleNext()

    if (!this.indexable) return
    this.index = (this.index - 1)
    this.toggleDot()
  }

  next () {
    this.contentTarget.scrollLeft += this.contentTarget.offsetWidth / 0.8
    this.togglePrevious()
    this.toggleNext()

    if (!this.indexable) return
    this.index = (this.index + 1)
    this.toggleDot()
  }

  // ==== Logic ==== //
  progress () {
    if (!this.ticking && this.hasProgressTarget) {
      window.requestAnimationFrame(() => {
        const x = (this.progressTarget.offsetWidth - this.scrollerTarget.offsetWidth) * this.scrollPercentage
        this.scrollerTarget.style.transform = `translateX(${x}px)`
        this.ticking = false
      })

      this.ticking = true
    }
  }

  togglePrevious () {
    if (!this.hasPreviousTarget) return
    this.toggleNavigation(this.previousTarget, !this.scrollable && this.scrollPercentage > 0)
  }

  toggleNext () {
    if (!this.hasNextTarget) return
    this.toggleNavigation(this.nextTarget, !this.scrollable && this.scrollPercentage < 1)
  }

  toggleDot () {
    this.dotTargets.forEach((dot, index) => {
      dot.classList.toggle('slider__dot--active', index === this.index)
    })
  }

  toggleNavigation (element, force) {
    element.style.display = force ? 'block' : 'none'
  }

  paginate () {
    if (!this.hasDotsTarget || this.itemTargets.length < 2) return

    this.itemTargets.forEach((item, index) => {
      const dot = document.createElement('span')

      dot.className = 'slider__dot'
      dot.setAttribute('data-target', 'slider.dot')
      dot.setAttribute('data-slider-dot-index', index)
      item.setAttribute('data-slider-item-index', index)

      this.dotsTarget.appendChild(dot)
    })

    this.toggleDot()
  }

  // ==== Getters and Setters ==== //

  get indexable () {
    return Boolean(this.data.get('indexable'))
  }

  get index () {
    return Number(this.data.get('index'))
  }

  set index (value) {
    return this.data.set('index', value)
  }

  get scrollable () {
    return this.data.get('scrollable') === 'true' && this.contentTarget.offsetWidth < this.contentTarget.scrollWidth
  }

  get scrollPercentage () {
    return this.contentTarget.scrollLeft / (this.contentTarget.scrollWidth - this.contentTarget.offsetWidth)
  }
}
