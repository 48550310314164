import Draggable from './lib/draggable'
import { Controller } from '@hotwired/stimulus'
import Rails from '../../../rails/rails-ujs'

export default class extends Controller {
  static targets = ['label', 'control', 'input']

  /* == Life Cycle == */
  connect () {
    this.draggable = new Draggable(this.controlTarget, this.drag.bind(this))
  }

  drag (event) {
    const sliderWidth = event.boundaries.width
    const value = event.pageX / sliderWidth

    this.labelTarget.textContent = (parseInt(value * 100)) + '%'
    event.target.style.paddingLeft = (value * 100) + '%'

    this.inputTarget.value = value.toFixed(2)
    Rails.fire(this.inputTarget, 'change')
  }
}
